@import 'sass/fontFamilies.scss';

.addWebContentHeader {
  display: flex;
  align-items: center;
  height: 10%;
  min-height: 60px;
  padding: 0 20px;
  background: #fff;
  border-bottom: var(--border);
  @media screen and (max-width: 800px) {
    position: sticky;
    top: 0;
  }
  .leftOne {
    flex-grow: 1;
    display: flex;
    align-items: center;
    .webIcon {
      width: 32px;
      height: 32px;
      margin-right: 10px;
    }
    .heading {
      font-family: $InterMedium;
      font-size: 16px;
      font-weight: 500;
      color: var(--grey-1);
    }
  }
  .rightOne {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    @media screen and (max-width: 800px) {
      display: none;
    }
    .btns {
      margin-left: 10px;
      font-family: $InterMedium;
      font-size: 15px;
      font-weight: 500;
    }
  }
}
