@import 'sass/fontFamilies.scss';

.screenDetailsHeader {
  padding: 15px 0 15px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--grey-6);
  background: var(--white);
  .colOne {
    display: flex;
    align-items: center;
    flex-grow: 1;
    .screenName {
      font-size: 16px;
      font-weight: 500;
      font-family: $InterMedium;
      @media screen and (min-width: 800px) {
        font-size: 21px;
        margin-right: 10px;
      }
    }
    .onlineStatusPill {
      display: none;
      @media screen and (min-width: 800px) {
        display: flex;
      }
    }
  }
  .colTwo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    // grid-gap: 20px;

    .headerBtns {
      display: none;
      font-size: 15px;
      font-weight: 500;
      margin-right: 20px;
      font-family: $InterMedium;
      @media screen and (min-width: 800px) {
        display: block;
      }
    }
    .moreBtn {
      padding: 10px;
      font-family: $InterRegular;
      &.activeMore {
        opacity: 0.75;
        background-color: rgb(239, 239, 239);
      }

      img {
        height: 10px;
      }
    }
    .morePopover,
    .morePopoverBtn {
      border-radius: 8px;
      right: -20px;
      top: 28px;
      border: 1px solid var(--grey-5);
      .moreWrapper,
      .popoverWrapper {
        width: 145px;
        .mutateOption,
        .deleteOption {
          display: flex;
          align-items: center;
          padding: 10px 15px;
          cursor: pointer;
          border-radius: 8px;
          &:last-child {
            padding-top: 5px;
          }
          img {
            margin-right: 5px;
            width: 16px;
          }
          font-family: $InterRegular;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.4;
          color: var(--grey-1);
          &:hover {
            background: var(--screenz-main-hover);
          }
          p {
            margin-top: 3px;
          }
        }
      }
    }
    @media screen and (min-width: 800px) {
      .moreIcon,
      .closeIcon {
        display: none;
      }
    }
  }
}
