@import 'sass/fontFamilies.scss';

.uploadContent {
  width: 100%;
  margin-bottom: 16px;
  position: relative;
  @media screen and (min-width: 800px) {
    max-width: 560px;
    width: 100vw;
  }
  .fileUploadArea {
    border: 1.5px dashed #a2a0c1;
    border-radius: 10px;
    background: #f8f8fb;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 170px;
    transition: 400ms;
    &.initialState {
      height: 281px;
      flex-direction: column;
    }
    .uploadContentIcon {
      width: 80px;
      margin-bottom: 12px;
    }
    .description {
      font-family: $InterRegular;
      font-size: 14px;
      color: var(--grey-1);
      max-width: 214px;
      text-align: center;
      margin-bottom: 15px;
    }
    .browseButton {
      font-family: $InterMedium;
      font-size: 15px;
      font-weight: 500;

      @media screen and (max-width: 800px) {
        padding: 0.6rem 0.8rem;
        margin-right: 1rem;
      }
    }
    input {
      font-size: 1490%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      max-width: 100%;
      height: 170px;
      &.initialState {
        height: 281px;
        flex-direction: column;
      }
    }
  }
  .uploadingMessage {
    display: flex;
    align-items: center;
    padding: 14px 30px;
    box-shadow: 0 4px 12px 0px rgba(0, 0, 0, 0.1);
    margin: 0 -15px;
    margin-bottom: 20px;
    .cloudUpload2Icon {
      margin-right: 12px;
    }
    span {
      font-family: $InterRegular;
      font-size: 14px;
      color: var(--bluey-grey);
    }
  }
  .filesUploading {
    list-style-type: none;
    padding: 0 5px;
    max-height: 0;
    overflow: auto;
    &.postInitialState {
      max-height: 223px;
      margin-bottom: -25px;
    }
  }
}
