@import 'sass/fontFamilies.scss';

.playlistContents {
  user-select: none;
  height: calc(100% - 60px);
  > div {
    height: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0; /* width of the entire scrollbar */
    }
  }
  .content {
    padding: 10px 10px 5px 10px;
    margin-bottom: 10px;
    background: var(--white);
    border-radius: 8px;
    border: var(--border);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
      background: var(--screenz-main-hover);
      border: 1px solid var(--grey-5);
    }
    .colOne {
      display: flex;
      align-items: center;
      .preview {
        width: 82.3px;
        height: 53.6px;
        margin-right: 10px;
        img {
          width: inherit;
          height: inherit;
          object-fit: cover;
        }
      }
      .contentNameAndType {
        .contentName {
          font-family: $InterRegular;
          font-size: 16px;
          color: #33373a;
        }
        .contentType {
          font-family: $InterRegular;
          font-size: 16px;
          color: #7f8fa4;
        }
      }
    }
    .colTwo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .timePickers {
        align-items: center;
        grid-gap: 5px;
        display: none;
        @media screen and (min-width: 800px) {
          display: flex;
          margin-left: -10rem;
        }
        .timePicker {
          //
        }
      }
      .dustbinIcon {
        width: 20px;
        height: 24px;
        @media screen and (min-width: 800px) {
          display: none;
        }
      }
    }

    @media screen and (min-width: 800px) {
      &:hover {
        .colTwo {
          // gap: 1rem;
          .timePickers {
            // margin-left: 0;
            z-index: 1;
          }
          .dustbinIcon {
            display: flex;
            margin-right: 0.2rem;
            z-index: 9;
            opacity: 0.6;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
