@import 'sass/fontFamilies.scss';

.notSetup {
  padding: 4px 8px;
  border-radius: 5px;
  background-color: var(--white);
  color: var(--fail);
  // font-family: $TTInterfacesMedium;
  font-size: 11px;
  border: solid 1px var(--fail);
}
