@import 'sass/fontFamilies.scss';

.columns {
  display: grid;
  grid-template-columns: 6fr 3fr 3fr;
  border-bottom: solid 1px var(--grey-6);
  padding: 17px 0;
  .column {
    font-family: $InterRegular;
    font-size: 13px;
    color: #7c8fa6;
    &.firstColumn {
      padding-left: 40px;
    }
  }
}
