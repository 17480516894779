@import 'sass/fontFamilies.scss';

.addContentModal {
  header {
    background: var(--grey-7) !important;
  }
  .assignContentBtn {
    font-size: 15px;
    font-weight: 500;
    font-family: $InterMedium;
    color: #fff;
    margin-right: 20px;
  }

  .assignContentWrapper {
    max-width: 800px;
    display: flex;
    flex-direction: column;
    height: 80vh;
    @media screen and (min-width: 800px) {
      height: 100%;
      width: 100vw;
      display: block;
    }
    .noPlaylistsFound {
      font-family: $InterMedium;
      // color: #fff;
      border: 1px solid #d8dae9;
      text-align: center;
      padding: 16px;
      border-radius: 8px;
      margin: 10px 0;
      &.desktop {
        @media screen and (max-width: 800px) {
          display: none;
        }
      }
      &.responsive {
        @media screen and (min-width: 800px) {
          display: none;
        }
      }
    }
    .playlists {
      tbody {
        height: auto;
        max-height: 250px;
      }
    }
    .searchBar {
      border-bottom: var(--border);
      margin: -15px -20px -15px -23px;
      padding: 10px;
      margin-bottom: 0px;
      @media screen and (min-width: 800px) {
        display: none;
      }
      .containers {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 10px;
        .container {
          cursor: pointer;
          border: 1px solid #dfe2e5;
          border-radius: 8px;
          padding: 7px;
          display: flex;
          align-items: center;
          font-family: $InterRegular;
          font-size: 13px;
          color: var(--grey-2);
          .folderIcon {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }
        }
      }
    }
    .clientNameAndFolderName {
      padding: 15px;
      border-bottom: var(--border);
      margin: 0 -22px;
      padding-bottom: 12px;
      @media screen and (min-width: 800px) {
        border-bottom: none;
        padding: none;
        // display: none;
      }
    }
    .folderListing {
      padding: 10px 15px 15px 0;
      border-bottom: var(--border);
      margin: 0 -22px 10px -22px;
      padding: 10px 20px;

      @media screen and (min-width: 800px) {
        border-bottom: none;
        // display: none;
      }
    }
  }
}
