@import '../../../sass//fontFamilies.scss';

.setNewPasswordWrapper {
  height: 100%;
  position: relative;
  .section {
    .container {
      width: 100%;
      min-width: 200px;
      transition: 400ms;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @media screen and (min-width: 480px) {
        max-width: 423px;
      }
      .logo {
        margin-bottom: 10px;
      }
      .headingOne {
        margin-bottom: 5px;
        font-size: 24px;
        font-weight: 600;
        color: #111216;
        font-family: $InterSemiBold;
      }
      .headingTwo {
        font-family: $InterRegular;
        font-size: 16px;
        margin-bottom: 32px;
        max-width: 336px;
        color: #111216;
      }
      .inputLabel {
        font-size: 13px;
        font-family: $InterRegular;
        color: #5b5d70;
        margin-bottom: 5px;
        input {
          background: #e9f0fd;
        }
      }
      .input {
        font-family: $InterRegular;
        font-size: 14px;
        // margin-bottom: 8px;
      }
      .passwordCriteria {
        font-size: 11px;
        font-weight: 500;
        font-family: $InterMedium;
        color: #8a8daa;
      }

      .resetPwdBtn {
        align-self: flex-end;
        font-family: $InterMedium;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
}
