.clientWrapper {
  height: 100%;
  overflow: hidden;
  .notFound {
    text-align: center;
    padding: 30px;
    font-weight: 500;
    font-family: 'Inter-Medium';
  }
}
