@import 'sass/fontFamilies.scss';

.emptyState {
  display: grid;
  align-items: center;
  justify-content: space-evenly;
  overflow: auto;
  position: relative;
  width: 90%;
  margin: 5vh auto;
  max-width: 1000px;
  padding-bottom: 34px;
  &::-webkit-scrollbar {
    width: 0px;
  }
  @media screen and (min-width: 800px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 800px) {
    order: 1;
    // padding: 0;
    flex-direction: column;
    max-height: 500px;
    overflow: auto;
    .descriptionArea {
      order: 2;
      text-align: center;
    }
  }

  .descriptionArea {
    margin: 0;
    .heading {
      font-size: 36px;
      width: 2rem;
      height: 44px;
      width: 361px;
      font-family: $InterSemiBold;
      font-weight: 600;
      color: #111216;
      background-color: #f4f5f7;
      margin-bottom: 17px;
      caret-color: rgb(136, 136, 145);
      border: none !important;

      &:focus {
        outline: none;
      }

      @media screen and (max-width: 800px) {
        margin: auto;
        text-align: center;
        width: 100%;
        font-size: 21px;
      }
    }
    .description {
      background-color: #f4f5f7;
      margin-bottom: 25px;
      font-size: 21px;
      font-weight: 500;
      font-family: $InterRegular;
      border: none !important;
      overflow: hidden;
      color: #5b5d70;
      resize: none;

      &:focus {
        outline: none;
      }
      @media screen and (max-width: 800px) {
        flex-direction: column;
        order: 1;
        padding: 0;
        font-size: 16px;
        width: 100%;
        padding: 1rem;
        text-align: center;
        min-height: 11vh;
      }
    }
    .screenzBtn {
      @media screen and (max-width: 800px) {
        margin: auto;
        margin-bottom: 3rem;
      }
    }

    @media screen and (max-width: 800px) {
      flex-direction: column;
      order: 1;
      padding: 0;
    }
  }

  .emptyStateImg {
    margin: 0;
    position: relative;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    overflow: hidden;
    @media screen and (max-width: 799px) {
      height: 250px;
      width: 250px;
      min-height: 254px;
      min-width: 254px;
      margin: auto;
    }
    @media screen and (min-width: 800px) {
      width: 100%;
      max-height: 350px;
      max-width: 350px;
      margin-left: auto;
    }
    img {
      border-radius: inherit;
      height: 100%;
      width: 100%;
      object-fit: cover;
      opacity: 1;
      transition: 300ms;
      &.loading {
        opacity: 0.2;
      }
    }

    .emptyStateLoader {
      position: absolute;
      > p {
        margin-bottom: 0px;
        font-size: 22px;
      }
    }

    .changeImgBtn {
      position: absolute;
      background-color: White;
      opacity: 0;
      transition: 300ms;
      cursor: default;
      img {
        width: 23px;
      }
    }
    .fileInput {
      position: absolute;
      font-size: 300px;
      width: 100%;
      opacity: 0;
      cursor: pointer;
    }
    &:hover {
      .changeImgBtn {
        &.show {
          cursor: pointer;
          opacity: 1;
        }
      }
    }
  }
}
