.cropProfilePic {
  width: 80vw;
  max-width: 592px;
  position: relative;
  height: 100vh;
  max-height: 440px;
  .sizeDragger {
    position: absolute;
    bottom: -52px;
    z-index: 1;
    display: flex;
    align-items: center;

    .slider {
      margin: 0 10px;
      width: 191px;
    }
    .smallIcon {
      width: 16px;
      height: 16px;
    }
    .largeIcon {
      width: 24px;
      height: 24px;
    }
  }
  :global {
    .reactEasyCrop_Container {
      img {
        height: 100%;
        width: 100%;
      }
      .reactEasyCrop_CropArea {
        color: rgba(255, 255, 255, 0.69);
      }
    }
  }
}
