@import 'sass/fontFamilies.scss';

.colorPicker {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  .colorPickerPopover {
    border-radius: 8px;
    border: 1px solid var(--grey-5);
  }
  .colors {
    border-radius: 8px;
    list-style: none;
    padding: 3px 0;
    :hover {
      background: var(--screenz-main-hover);
      cursor: pointer;
    }
    .colorWrapper {
      &.active {
        background: var(--screenz-main-hover);
      }
      width: 50px;
      height: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      .color {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        transition: 400ms;
      }
    }
  }
  .colorText {
    font-family: $InterRegular;
    font-size: 15px;
    color: var(--grey-3);
  }
  .colorChooseBtn {
    cursor: pointer;
    border: 1px solid #888ca9;
    background: transparent;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    .choosenColor {
      height: 24px;
      width: 24px;
      margin-right: 12px;
      border-radius: 24px;
    }
    img {
      width: 13px;
      height: 13px;
    }
  }
}
