.listAndDetails {
  min-height: 100%;
  flex-grow: 1;
  overflow: hidden;

  @media screen and (max-width: 500px) {
    margin-bottom: 5rem;
  }
  @media screen and (min-width: 800px) {
    display: grid;
    height: 100%;
    grid-template-columns: 1fr;

    &.displayboth {
      grid-template-columns: 3fr 1fr;
    }
  }
  .foldersAndScreensWrapper {
    max-height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 129px;
    .foldersWrapper {
      display: flex;
      flex-wrap: wrap;
      grid-gap: 10px;
      padding: 20px 20px 10px 20px;
    }
    .screenList {
      padding: 20px;
      flex-grow: 1;
      &::-webkit-scrollbar {
        width: 0; /* width of the entire scrollbar */
      }
      .playlistTable {
        @media screen and (max-width: 800px) {
          display: none;
        }
        tbody {
          height: 100% !important;
          max-height: unset;
        }
      }
      .forMobile {
        @media screen and (min-width: 800px) {
          display: none;
        }
      }
    }
    @media screen and (max-width: 800px) {
      display: none;
      &.Playlists {
        display: block;
      }
    }
  }
  .detailView {
    height: 100%;
    margin-top: -1.5px;
    @media screen and (max-width: 800px) {
      display: none;
      &.PlaylistsDetails {
        display: flex;
      }
    }
  }
}
