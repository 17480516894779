@import 'sass/fontFamilies.scss';

.delayMessage {
  text-align: center;
  font-size: 16px;
  font-family: $InterRegular;
  color: #33373a;
  display: inline-block;
  padding: 15px 0;
  max-width: 460px;
  width: 100vw;
}

.labelParagraph {
  font-family: $InterRegular;
  font-size: 14px;
  margin-bottom: 1rem;
}
.deleteConfirmationMsg {
  background: #f8eaec;
  padding: 14px;
  border-radius: 5px;
  font-family: $InterRegular;
  font-size: 12px;
  text-align: center;
  margin-bottom: 2rem;
}
.deleteModal {
  width: 100%;
  @media screen and (min-width: 800px) {
    max-width: 460px;
    width: 100vw;
  }
  .labelAndInput {
    margin-bottom: 10px;
    @media screen and (min-width: 800px) {
      display: flex;
      align-items: center;
    }
    margin-bottom: 15px;
    p {
      text-transform: capitalize;
    }
    .label {
      font-size: 13px;
      font-family: $InterRegular;
      color: #33373a;
      display: inline-block;
      width: 102px;
      margin-bottom: 10px;
    }
    .input {
      width: 100%;
      flex-grow: 1;
      font-size: 14px;
      font-family: $InterRegular;
      background: transparent;
      color: #33373a;
      @media screen and (min-width: 800px) {
        width: auto;
      }
    }
  }
}
