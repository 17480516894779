@import 'sass/fontFamilies.scss';

.subheaderTwo {
  padding: 5px 10px;
  background: var(--white);
  @media screen and (min-width: 800px) {
    display: none;
  }
  &.username {
    border-bottom: 1px solid #d8dae9;
    background: #f4f5f7;
  }
  .username {
    padding: 20px 5px;
    font-size: 16px;
    font-weight: 500;
    font-family: $InterMedium;
  }
  .button {
    font-size: 14px;
    font-weight: 500;
    font-family: $InterMedium;
    padding: 10px;
    border: 0;
    margin-right: 5px;
    border-radius: 8px;
    background: transparent;
    cursor: pointer;
    &.active {
      color: #615ae2;
      background: #c6d3f9;
    }
  }
}
