@import 'sass/fontFamilies.scss';

.verify2fa {
  height: 100%;
  padding: 20px;
  .section {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .verify2faContainer {
      width: 100%;
      min-width: 200px;
      transition: 400ms;
      @media screen and (min-width: 480px) {
        max-width: 423px;
      }
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .logo {
        margin-bottom: 10px;
      }
      .verify2faHeading {
        margin-bottom: 5px;
        font-size: 24px;
        font-weight: 600;
        color: var(--grey-1);
        font-family: $InterSemiBold;
      }
      .verify2faDescription {
        font-family: $InterRegular;
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 25px;
      }

      .clientsList {
        border: var(--border);
        border-radius: 8px;
        width: 100%;
      }
      .otpInputs {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        input {
          text-align: center;
          margin-right: unset !important;

          font-size: 18px;
          border: 1.3px solid var(--grey-5);
          border-radius: 4px;
          display: block;
          @media screen and (min-width: 800px) {
            width: 50px !important;
            height: 50px !important;
          }
          &:focus {
            outline: none;
          }
        }
      }
      .verifyBtn {
        margin-left: auto;
        font-family: $InterMedium;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
}
