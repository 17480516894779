.listAndDetails {
  height: 100%;
  overflow: hidden;
  @media screen and (min-width: 800px) {
    display: grid;
    height: 100%;
    grid-template-columns: 1fr;

    &.displayboth {
      grid-template-columns: 3fr 1fr;
    }
  }
  .foldersAndScreensWrapper {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    .foldersWrapper {
      display: flex;
      flex-wrap: wrap;
      grid-gap: 10px;
      padding: 20px 20px 10px 20px;
    }
    .screenList {
      padding: 2px;
      flex-grow: 1;
      display: grid;
      margin-bottom: 7rem;
      grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
      grid-auto-rows: minmax(min-content, max-content);
      &::-webkit-scrollbar {
        width: 0; /* width of the entire scrollbar */
      }
      @media screen and (min-width: 800px) {
        display: none;
        padding: 15px 20px 20px 20px;
      }
    }
    .gridContainer {
      padding: 0 10px;
      display: none;
      @media screen and (min-width: 800px) {
        display: block;
      }
      > div {
        // display: grid;
        // display: grid !important;
        // grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
        // grid-template-columns: 1fr 1fr 1fr 1fr;
        // grid-auto-rows: minmax(min-content, max-content);
        // overflow-x: hidden;
        // grid-gap: 10px;
        // flex-wrap: wrap;
        &:last-child {
          // width: 67%;

          > div {
            max-width: 350px;
          }
        }

        > div {
          // max-width: 270px;
          // min-width: 300px;
          min-width: 270px;
          width: 100%;
          // max-width: 350px;
        }
      }
    }
    @media screen and (max-width: 800px) {
      display: none;
      &.Screens {
        display: flex;
      }
    }
  }
  .detailView {
    height: 100%;
    margin-top: -1.5px;
    @media screen and (max-width: 800px) {
      display: none;
      &.ScreensDetails {
        display: flex;
      }
    }
  }
}
