@import 'sass/fontFamilies.scss';

.profilePictureWrapper {
  padding: 20px 10px;
  background: var(--white);
  border-bottom: var(--border);
  @media screen and (min-width: 800px) {
    border: 0;
    padding-top: 28px;
  }
  .sectionHeading {
    font-family: $InterMedium;
    font-size: 15px;
    font-weight: 500;
    color: var(--grey-1);
    margin-bottom: 5px;
  }
  .profilePicAndActions {
    display: flex;
    margin-bottom: 18px;
    @media screen and (min-width: 800px) {
      margin-bottom: 32px;
    }
    .profilePic {
      max-width: 80px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 80px;
      background: #615ae2;
      border-radius: 100%;
      margin-right: 5px;
      > span {
        color: #ffffff;
        font-size: 31px;
        font-family: $InterRegular;
        &:hover{
          visibility: hidden;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: inherit;
      }
      .uploadPro {
        position: relative;
        visibility: hidden;
        input {
          position: absolute;
          width: 100%;
          height: 100%;
          font-size: 25px;
          opacity: 0;
          cursor: pointer;
        }
        .addImage{
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          cursor: pointer;
        }  
      }
      }
      .profilePic:hover .uploadPro{
        visibility: visible;
      }
  }
  .actionsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .uploadProfilePic {
      position: relative;
      input {
        position: absolute;
        width: 100%;
        height: 100%;
        font-size: 25px;
        opacity: 0;
        cursor: pointer;
      }
    }
    .upload,
    .delete {
      font-family: $InterMedium;
      font-size: 11px;
      font-weight: 500;
      padding: 8px;
      img {
        height: 16px;
        width: 16px;
      }
    }
    .upload {
      background-color: #615ae2;
    }
  }
  .saveChanges {
    font-family: $InterMedium;
    font-size: 15px;
    font-weight: 500;
  }
}
