/* The container */
.container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 16px;
  width: 16px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

/* Create a custom checkbox */
.radioMark {
  height: 100%;
  width: 100%;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--screenz-main);
}

/* Create the radioMark/indicator (hidden when not checked) */
.radioMark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the radioMark when checked */
.container input:checked ~ .radioMark:after {
  display: block;
}

/* Style the radioMark/indicator */
.container .radioMark:after {
  width: 11px;
  height: 11px;
  border-radius: 100%;
  background-color: var(--screenz-main);
}

// .customRadio {
//   height: 20px;
//   width: 20px;
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 100%;
//   border: 1px solid var(--screenz-main);
//   input {
//     height: 100%;
//     width: 100%;
//     position: absolute;
//     z-index: 1;
//     opacity: 0;
//   }
//   .checked {
//     height: 80%;
//     width: 80%;
//     position: absolute;
//     background-color: var(--screenz-main);
//     border-radius: 100%;
//   }
// }
