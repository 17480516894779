@import "sass/fontFamilies.scss";
.invitedAvatarContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    .invitedAvatarCircle {
        width: 36px;
        height: 36px;
        background-color: #d8dae9;
        text-align: center;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        margin-right: 5px;
        .invitedAvatarText {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            font-family: $InterRegular;
            font-size: 14px;
            color: #ffffff;
            > span {
                display: flex;
                align-items: center;
                justify-content: center;
              }
              img {
                height: 100%;
                width: 100%;
                object-fit: fill;
                border-radius: 100%;
                box-shadow: none;
              }
        }
    }
    .invitedNameContainer {
        padding: 0;
        .invitedNameDetails {
            font-size: 14px;
            font-family: $InterRegular;
            line-height: 1.4;
            font-weight: normal;
            color: #111216;
        }
        .invitedEmailDetails {
            font-size: 13px;
            font-family: $InterRegular;
            text-align: left;
            color: #8a8daa;
        }
    }
}
