.mainContent {
  flex-grow: 1;
  overflow: auto;
  padding: 0 10px;
  padding-top: 30px;
  .container {
    display: grid;
    grid-template-columns: 45% 55%;
  }
}
