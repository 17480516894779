@import 'sass/fontFamilies.scss';

.chooseClient {
  height: 100%;
  .section {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .chooseClientContainer {
      width: 100%;
      min-width: 200px;
      transition: 400ms;
      @media screen and (min-width: 480px) {
        max-width: 423px;
      }
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .logo {
        margin-bottom: 10px;
      }
      .chooseClientHeading {
        margin-bottom: 5px;
        font-size: 24px;
        font-weight: 600;
        color: var(--grey-1);
        font-family: $InterSemiBold;
      }
      .chooseClientDescription {
        font-family: $InterRegular;
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 42px;
      }

      .clientsList {
        border: var(--border);
        border-radius: 8px;
        width: 100%;

        .client {
          display: flex;
          align-items: center;
          padding: 20px 15px;
          border-bottom: var(--border);
          &:first-child {
            border-radius: 8px 8px 0 0;
          }
          &:last-child {
            border-bottom: 0;
            border-radius: 0 0 8px 8px;
          }
          &:hover {
            cursor: pointer;
            background: var(--screenz-main-hover);
            .arrow {
              display: block;
              opacity: 1;
            }
          }
          .logo {
            margin-right: 10px;
            margin-bottom: 0;
          }
          .details {
            .name {
              font-family: $InterMedium;
              font-size: 16px;
              font-weight: 500;
              letter-spacing: -0.32px;
              color: var(--grey-1);
            }
            .lastLogin {
              font-family: $InterRegular;
              font-size: 14px;
              line-height: 1.4;
              color: var(--grey-4);
            }
          }
          .arrow {
            display: none;
            opacity: 0;
            margin-left: auto;
            transition: 700ms;
          }
        }
      }
    }
  }
}
