@import 'sass/fontFamilies.scss';

.tableHead {
  border: 1px solid var(--grey-6);
  border-radius: 10px;
  width: 100%;
  margin-bottom: 5px;
  background: var(--white);
  @media screen and (max-width: 800px) {
    display: none;
  }

  thead {
    tr {
      grid-template-columns: 53% 15% 20% 12%;
      display: grid;
      padding: 15px 40px;
      border-bottom: 1px solid var(--grey-6);
      th {
        text-align: left;
        display: flex;
        align-items: center;
        white-space: nowrap;
        font-family: $InterRegular;
        font-size: 14px;
        margin-left: 5px;
        font-weight: 500;
        color: #7f8fa4;
      }
    }
  }
  tbody {
    display: block;
    max-height: 400px;
    max-height: fit-content;
    margin-bottom: 10px;
    tr {
      // grid-template-columns: 40% 14% 19% 16.5% 2%;
      grid-template-columns: 50% 10% 22% 12% 0%;
      display: grid;
      padding: 15px 10px;
      border-bottom: 1px solid var(--grey-6);
      cursor: pointer;
      transition: 200ms;
      &.active {
        background: var(--screenz-main-hover);
      }
      &:hover {
        background: var(--screenz-main-hover);
      }
      td {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: var(--grey-3);
        font-size: 14px;
        font-family: $InterRegular;
        margin-left: 35px;
        white-space: nowrap;
        &.lastActive {
          font-size: 14px;
          text-align: left;
          color: var(--grey-2);
        }
      }
    }
  }

  .morePopupContainer {
    .popover {
      right: 0;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    }
    .morePopup {
      right: 0;
      border-radius: 8px;
      display: flex;
      align-items: flex-start;
      padding: 10px;
      font-family: $InterRegular;
      font-size: 14px;
      line-height: 1.36;
      color: var(--grey-1);
      img {
        width: 24px;
        height: 24px;
      }
      p {
        white-space: nowrap;
      }
      &:hover {
        background: var(--screenz-main-hover);
      }
    }
    .moreBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 300ms;
      margin-right: 5px;

      .icon {
        path {
          fill: var(--grey-4);
        }
      }
      &:hover {
        // background: var(--grey-6);
        border-radius: 5px;
        .icon {
          path {
            fill: var(--grey-3);
          }
        }
      }
    }
  }
}
