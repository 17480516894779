.popoverContainer {
  position: relative;
  // display: flex;
  .popover {
    position: absolute;
    background: #fff;
    box-shadow: 0 2px 6px 0 rgba(42, 44, 58, 0.25);
    z-index: 1000;
    // border: 1px solid var(--grey-5);
    .arrow {
      box-shadow: -3px -3px 5px 0px rgba(42, 44, 58, 0.2);
      height: 15px;
      width: 15px;
      display: block;
      position: absolute;
      border: 1px solid var(--grey-5);
      top: -8px;
      left: calc(50% - 9px);
      transform: rotate(45deg);
      border-right: 0;
      border-bottom: 0;
    }
  }
}
