@import 'sass/fontFamilies.scss';

.emptyState {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 5vh;
  padding: 0 25px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    order: 1;
    .descriptionArea {
      order: 2;
      text-align: center;
    }
  }
  .descriptionArea {
    .heading {
      font-size: 36px;
      font-family: $InterSemiBold;
      font-weight: 600;
      color: #111216;
      margin-bottom: 17px;
    }
    .description {
      color: #5b5d70;
      max-width: 495px;
      margin-bottom: 25px;
      font-size: 21px;
      font-weight: 500;
      font-family: $InterRegular;
    }
    .screenzBtn {
      @media screen and (max-width: 800px) {
        margin: auto;
      }
    }
  }
  .emptyStateImg {
    width: 50%;
    max-width: 421px;
    @media screen and (max-width: 800px) {
      width: 75%;
    }
  }
}
