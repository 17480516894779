@import 'sass/fontFamilies.scss';
.filterPopover {
  border-radius: 8px;
  right: -20px;
  top: 33px;
  .filterWrapper {
    min-width: 140px;
    border: 1px solid var(--grey-5);
    border-radius: inherit;
    cursor: pointer;
    .checkBoxContainer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 1vh;
      .checkBox {
        margin-right: 5px;
        width: 16px;
        height: 16px;
        border-radius: 3px;
      }
      .checkBoxList {
        font-size: 14px;
        margin-left: 0.5rem;
        font-family: $InterRegular;
        color: var(--grey-3);
      }
      &:hover {
        background: var(--screenz-main-hover);
        // border-radius: inherit;
      }

      &:first-child {
        &:hover {
          background: var(--screenz-main-hover);
          border-top-left-radius: inherit;
          border-top-right-radius: inherit;
        }
      }

      &:last-child {
        &:hover {
          background: var(--screenz-main-hover);
          border-bottom-left-radius: inherit;
          border-bottom-right-radius: inherit;
        }
      }
    }
  }
}
