@import 'sass/fontFamilies.scss';

.timePickerPopup {
  background: var(--white);
  top: 38px;
  border-radius: 5px;
  ::-webkit-scrollbar {
    width: 0; /* width of the entire scrollbar */
  }
  .secondsAndMinutes {
    // list-style: none;
    // max-height: 200px;
    // width: 65px;
    // overflow: auto;
    // text-align: center;
    .timeInput {
      width: 58px;
      height: 30px;
      border-radius: 5px;
      border: 1px solid var(--grey-5);
      margin: 5px;
      padding: 5px;
      color: var(--grey-5);
      font-family: $InterRegular;
      font-size: 14px;
      &::placeholder {
        color: var(--grey-5);
      }
      &:focus {
        outline: none !important;
      }
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        -moz-appearance: textfield;
      }
    }
    ul {
      list-style: none;
      max-height: 200px;
      width: 65px;
      overflow: auto;
      text-align: center;
      li {
        padding: 5px 8px;
        color: var(--bluey-grey);
        font-family: $InterRegular;
        font-size: 14px;
        transition: 200ms;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          width: 18px;
          height: 18px;
        }
        &.active {
          background: var(--screenz-main-hover);
          color: var(--screenz-main);
        }
        &:hover {
          background: var(--screenz-main-hover);
          color: var(--screenz-main);
        }
      }
    }
  }
}
.timePicker {
  font-family: $InterRegular;
  font-size: 13px;
  color: var(--bluey-grey);
  width: 58.7px;
  height: 36px;
  border: 1px solid var(--grey-5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  .dropdownIcon {
    width: 10px;
    margin-left: 10px;
  }
  &.opened {
    border: 1px solid var(--screenz-main);
  }
}
