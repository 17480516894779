@import 'sass/fontFamilies.scss';

.playlistViewHeader {
  border-bottom: var(--border);
  background: var(--white);
  padding: 16px 20px;
  display: flex;
  align-items: center;
  .saveAndCloseBtn {
    font-family: $InterMedium;
    font-size: 15px;
    font-weight: 500;
  }
  .saveBtn {
    font-family: $InterMedium;
    font-size: 16px;
    font-weight: 500;
    // color: var(--screenz-main);
    background: var(--white) !important;
    border: none;
    @media screen and (min-width: 800px) {
      display: none;
    }
  }
  .colOne,
  .colTwo {
    flex-grow: 1;
    display: flex;
    align-items: center;
    grid-gap: 10px;
  }
  .colOne {
    .playlistName {
      color: var(--grey-1);
      font-family: 'Inter-Medium';
      font-size: 21px;
      font-weight: 100 !important;

      @media screen and (max-width: 799px) {
        font-size: 16px;
      }
    }
  }
  .colTwo {
    @media screen and (max-width: 799px) {
      display: none;
    }
    justify-content: flex-end;
    .colorText {
      font-family: $InterRegular;
      font-size: 15px;
      color: var(--grey-3);
    }
    .headerBtns {
      height: 40px;
      background: var(--white);
    }
    .morePopover {
      border-radius: 8px;
      right: -20px;
      top: 33px;
      .moreWrapper {
        width: 15vw;
        border: 1px solid var(--grey-5);
        border-radius: inherit;
        .mutateOption {
          display: flex;
          align-items: center;
          padding: 10px 15px;
          cursor: pointer;
          img {
            margin-right: 5px;
          }
          font-family: $InterRegular;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.4;
          color: var(--grey-1);
          // width: fit-content;
          &:hover {
            background: var(--screenz-main-hover);
          }
        }
      }
    }
    .eyeBtn {
      padding: 10px;
      background: var(--white);
      img {
        margin-right: 0;
        width: 20px;
        height: 20px;
      }
    }
    .moreBtn {
      padding: 10px;
      font-family: $InterRegular;

      &.activeMore {
        opacity: 0.75;
        background-color: rgb(239, 239, 239);
      }

      img {
        height: 10px;
      }
    }
  }
}
