@import 'sass/fontFamilies.scss';

.uploadWebContent {
  width: 100%;
  margin-bottom: 16px;
  position: relative;
  @media screen and (min-width: 800px) {
    max-width: 470px;
    width: 100vw;
  }
  .input {
    width: 100%;
    flex-grow: 1;
    font-size: 14px;
    font-family: $InterRegular;
    background: transparent;
    color: #33373a;
    margin-bottom: 1rem;
    @media screen and (min-width: 800px) {
      width: auto;
    }
  }
  .labelAndInput {
    margin-bottom: 10px;
    p {
      font-family: $InterRegular;
      font-size: 13px;
      color: var(--grey-3);
      line-height: 18.2px;
      margin-bottom: 5px;
    }
    input {
      background: var(--white);
      border: var(--border);
      border-color: #acaec5;
      color: var(--bluey-grey);
      font-family: $InterRegular;
      &:focus {
        outline: none;
        border-color: #615ae2;
      }
    }
  }
  .refreshContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 36px;
    .refreshTitleContainer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        margin-right: 10px;
      }
      .refreshTitle {
        font-size: 14px;
        font-family: $InterRegular;
        color: var(--grey-3);
      }
    }
    .dropDown {
      background: var(--white);
      border: var(--border);
      border-color: #acaec5;
      color: var(--bluey-grey);
      font-family: $InterRegular;
      font-size: 14px;
      font-family: $InterRegular;
      border-radius: 5px;
      padding: 10px;
      width: 100%;
      margin-bottom: 10px;
      &:focus {
        outline: none;
        border-color: #615ae2;
      }
      .dropDownOptions {
        font-size: 14px;
        font-family: $InterRegular;
        color: #8a8daa;
      }
    }
  }
}
