@import 'sass/fontFamilies.scss';

.clientView {
  display: grid;
  grid-gap: 15px;
  @media screen and (min-width: 450px) {
    grid-template-columns: repeat(auto-fill, minmax(314px, 1fr));
  }
  .clientCard {
    border: 1px solid #d8dae9;
    border-radius: 5px;
    .header {
      border-bottom: 1px solid #d8dae9;
      background: #fff;
      padding: 15px;
      border-radius: 5px 5px 0 0;
      .clientName {
        color: #111216;
        font-size: 14px;
        font-weight: 500;
        font-family: $InterMedium;
        margin-bottom: 10px;
      }
      .statusContainer {
        display: flex;
        justify-content: space-evenly;
        grid-gap: 15px;
        .status {
          border-radius: 10px;
          @media screen and (min-width: 800px) {
            min-width: 135px;
          }
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 10px;
          &.online {
            background: #e3f5e6;
          }
          &.offline {
            background: #f0f3f8;
          }
          .statusIcon {
            height: 40px;
            width: 40px;
            border-radius: 100%;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            &.connected {
              background: #36af47;
            }
            &.disconnected {
              background: #acaec5;
            }
          }
          .count {
            font-size: 28px;
            font-weight: 500;
            font-family: $InterMedium;
            color: #000000;
          }
          .message {
            font-size: 15px;
            font-family: $InterRegular;
            &.online {
              color: #739a78;
            }
            &.offline {
              color: #8a8daa;
            }
          }
        }
      }
    }
    .footer {
      padding: 15px;
      border-radius: 0 0 5px 5px;
      display: flex;
      justify-content: space-between;
      background: #f9fafb;
      .clientsCount {
        font-family: $InterRegular;
        font-size: 13px;
        span:nth-child(1) {
          color: #8a8daa;
        }
        span:nth-child(2) {
          color: #111216;
        }
      }
      .gotoClient {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 13px;
        color: #8a8daa;
        font-family: $InterRegular;
        > img {
          margin-left: 5px;
        }
      }
    }
  }
}
