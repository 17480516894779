@import 'sass/fontFamilies.scss';

.profileWrapper {
  display: flex;
  align-items: center;
  padding: 20px 15px;
  background: #fff;
  border-bottom: 1px solid #d8dae9;
  cursor: pointer;
  &:hover {
    background: var(--screenz-main-hover);
  }
  .avatar {
    margin-right: 5px;
  }
  > p {
    font-family: $InterMedium;
    font-size: 14px;
    font-weight: 500;
    color: #111216;
  }
}
