.topPattern {
  position: absolute;
  top: -0;
  right: 0;
  width: 31%;
  @media screen and (max-width: 480px) {
    right: 0px;
    width: 35%;
    bottom: 10;
  }
}

.bottomPattern {
  position: absolute;
  bottom: -0;
  left: -109px;
  width: 35%;
  @media screen and (max-width: 480px) {
    left: 0px;
    width: 50%;
    bottom: -0;
  }
}
