@import 'sass/fontFamilies.scss';

.details {
  background: var(--white);
  border-left: 1px solid var(--grey-6);
  @media screen and (max-width: 799px) {
    display: none;
  }
  .header {
    padding: 11px 15px;
    border-left: 1px solid var(--grey-6);
    border-bottom: 1px solid var(--grey-6);
    height: 61px;
    align-items: center;
    display: none;
    @media screen and (min-width: 800px) {
      display: flex;
      margin-left: -1px;
    }
    .playlistsIcon {
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }
    .heading {
      font-family: $InterMedium;
      font-size: 14px;
      font-weight: 500;
      color: #354052;
    }
  }
  .screenNameAndHashCode {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    grid-template-columns: 2fr 60px;
    grid-gap: 11px;
    padding: 15px 20px 27px 20px;
    border-bottom: 1px solid var(--grey-6);
    @media screen and (max-width: 800px) {
      border-top: 1px solid var(--grey-6);
    }
    .playlistName {
      p {
        font-size: 13px;
        font-family: $InterMedium;
        color: #354052;
        // margin-bottom: 12px;
        @media screen and (max-width: 799px) {
          display: none;
        }
      }
      input {
        background: var(--white);
        color: var(--grey-1);
        padding: 8px 10px;
      }
    }
    .saveBtn {
      background: var(--grey-7);
      padding: 8px 15px;
      opacity: 0.6;
      border: 1px solid var(--grey-5);
    }
  }
  .contentSection {
    padding: 15px;
    @media screen and (max-width: 800px) {
      border-bottom: 1px solid var(--grey-6);
    }
    .sectionHeading {
      font-family: $InterMedium;
      font-size: 14px;
      font-weight: 500;
      color: #354052;
      margin-bottom: 16px;

      @media screen and (min-width: 500px) {
        display: none;
      }
    }

    .sectionHeadingSize {
      font-family: $InterMedium;
      font-size: 14px;
      font-weight: 500;
      color: #354052;
      margin-bottom: 16px;

      @media screen and (max-width: 500px) {
        display: none;
      }
    }
    .deviceTypeContainer {
      display: flex;
      .deviceType {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        margin-right: 2rem;
        .screenIcon {
          width: 24px;
          height: 19px;
          margin-right: 7px;
          path {
            fill: var(--bluey-grey);
          }
        }
        span {
          color: var(--bluey-grey);
          font-family: $InterRegular;
          font-size: 14px;
        }
      }

      .desktopSizeDisappear {
        @media screen and (min-width: 500px) {
          display: none;
        }
      }
    }

    .deviceScreenSize {
      display: flex;
      align-items: center;
      grid-gap: 4px;
      margin-left: 4rem;

      @media screen and (min-width: 500px) {
        margin-left: 0;
      }
      input {
        width: 58px;
        height: 34px;
        background: transparent;
        color: var(--bluey-grey);
        font-family: $InterRegular;
        font-size: 13px;
      }
      span {
        color: var(--bluey-grey);
      }
      .saveBtn {
        padding: 10px;
        background: var(--grey-7);
        margin-left: 10px;
        opacity: 0.6;
        border: 1px solid var(--grey-5);

        @media screen and (max-width: 500px) {
          display: none;
        }
      }
    }
  }
  .colorPicker {
    padding: 10px 15px;
    border-bottom: var(--border);
    border-left: 1px solid var(--grey-6);
    p {
      font-family: $InterMedium;
      color: var(--grey-1);
    }
    display: none;

    @media screen and (min-width: 800px) {
      display: none;
    }
  }
  .mutateOption {
    display: flex;
    align-items: center;
    padding: 18px 15px;
    border-bottom: var(--border);
    border-left: 1px solid var(--grey-6);
    cursor: pointer;
    img {
      margin-right: 5px;
    }
    font-family: $InterMedium;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    color: var(--grey-1);

    @media screen and (min-width: 800px) {
      display: none;
    }
  }
  .shareAccess {
    padding: 15px;
  }
}
