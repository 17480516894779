@import 'sass/fontFamilies.scss';

%standard {
  background: var(--screenz-main);
  border: 1px solid #5e5be1;
  color: #fff;
  // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  &:hover {
    background: #4e48b5;
    &:disabled {
      background: #aba8ed;
      color: var(--white);
      border: 1px solid var(--grey-6);
    }
  }
  &:disabled {
    background: #aba8ed;
  }

  &.deleteBtn {
    &.active {
      background-color: #e94c5c;
      border: 1px solid #e94c5c;
    }
    &:hover {
      background: #e94c5c;
    }
  }
}
%transparent {
  color: #888ca9;
  border: 1px solid #888ca9;
  background: transparent;
  &:hover {
    color: var(--screenz-main);
  }

  &.deleteBtn {
    &:hover {
      color: #e94c5c;
    }
  }
}

.button {
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 400ms;

  .icon {
    height: 21px;
    width: 21px;
    margin-right: 5px;
    &.right {
      margin-right: 0;
      margin-left: 5px;
    }
    &.center {
      margin-right: 2.5px;
      margin-left: 2.5px;
    }
  }
  &.exSmallStd {
    @extend %standard;
    padding: 5px 10px;

    &.deleteBtn {
      &.active {
        background: #e94c5c;
      }
    }
  }
  &.smallStd {
    @extend %standard;
    padding: 10px 15px;

    &.deleteBtn {
      .active {
        background-color: #e94c5c;
        border: 1px solid #e94c5c;
        &:hover {
          background: #e94c5c;
        }
      }
    }
  }
  &.mediumStd {
    @extend %standard;
    padding: 15px 20px;

    &.deleteBtn {
      &.active {
        background: #e94c5c;
      }
    }
  }
  &.largeStd {
    @extend %standard;
    padding: 20px 25px;

    &.deleteBtn {
      &.active {
        background: #e94c5c;
      }
    }
  }
  &.exSmallTransp {
    @extend %transparent;
    padding: 5px 10px;
  }
  &.smallTransp {
    @extend %transparent;
    padding: 10px 15px;
  }
  &.mediumTransp {
    @extend %transparent;
    padding: 15px 20px;
  }
  &.largeTransp {
    @extend %transparent;
    padding: 20px 25px;
  }
  &.changeView {
    background: transparent;
    padding: 8px 10px;
    color: var(--grey-3);
    &.active {
      background: var(--screenz-light-2);
      color: #615ae2;
      font-family: $InterMedium;
    }
  }
  // &.deleteBtn {
  //   &.active {
  //     background: #e94c5c !important;
  //   }
  // }
  &:disabled {
    background: var(--grey-6);
    background: #aba8ed;
    color: var(--white);
    border: 1px solid var(--grey-6);
  }
  &.smallStd {
    @extend %standard;
    padding: 10px 15px;
  }
}
