@import 'sass/fontFamilies.scss';

.usersList {
  padding-right: 15px;
  margin-right: -22px;
  &.sharedWithList {
    margin: 0 -22px 0 -5px;
    min-height: 250px;
    max-height: 300px;
    overflow: auto;
  }
  .avatarAndName {
    display: flex;
    align-items: center;
    margin: 13px 0;
    cursor: pointer;
    .avatarWithEmail {
      display: flex;
    }
    .name {
      margin-left: 10px;
      font-family: $InterRegular;
      font-size: 14px;
      line-height: 1.4;
      color: var(--grey-1);
      text-transform: capitalize;
    }
    .email {
      margin-left: 10px;
      font-family: $InterRegular;
      font-size: 13px;
      line-height: 1.4;
      color: var(--grey-4);
    }

    .owner {
      margin-left: auto;
      font-family: $InterRegular;
      font-size: 14px;
      line-height: 1.4;
      color: var(--grey-1);
    }
    .canEdit {
      display: flex;
      align-items: center;
      margin-left: auto;
      span {
        font-family: $InterRegular;
        font-size: 14px;
        line-height: 1.4;
        color: var(--grey-1);
      }
      img {
        width: 10px;
        margin-left: 14px;
      }
    }
  }
}
