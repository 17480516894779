@import 'sass/fontFamilies.scss';

%avatarAndName {
  display: flex;
  align-items: center;
  margin: 13px 0;
  cursor: pointer;
  .name {
    margin-left: 10px;
    font-family: $InterRegular;
    font-size: 14px;
    line-height: 1.4;
    color: var(--grey-1);
    text-transform: capitalize;
  }
  .email {
    margin-left: 10px;
    font-family: $InterRegular;
    font-size: 13px;
    line-height: 1.4;
    color: var(--grey-4);
  }

  .owner {
    margin-left: auto;
    font-family: $InterRegular;
    font-size: 14px;
    line-height: 1.4;
    color: var(--grey-1);
  }
  .canEdit {
    display: flex;
    align-items: center;
    margin-left: auto;
    span {
      font-family: $InterRegular;
      font-size: 14px;
      line-height: 1.4;
      color: var(--grey-1);
    }
    img {
      width: 10px;
      margin-left: 14px;
    }
  }
}

.shareWrapper {
  border-top: var(--border);
  .heading {
    font-family: $InterMedium;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    color: #33373a;
    margin-bottom: 10px;
  }
  .avatarGroup {
    margin-bottom: 15px;
  }

  .shareBtn {
    font-family: $InterMedium;
    font-size: 14px;
    font-weight: 500;
    color: var(--screenz-main);
    border: 1.2px solid #7973e6;
  }
}
.shareModal {
  width: 90vw;
  max-width: 500px;
  .searchUsers {
    margin: 0 -22px;
    padding: 20px;
    border-bottom: var(--border);
    .searchDropdown {
      border: 1.5px solid var(--grey-6);
      margin-bottom: 20px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      transition: 300ms;
      position: relative;
      &.focused {
        border: 1.5px solid var(--screenz-main);
        box-shadow: 0 0 1px 4px #d5d3eb;
      }
      .search {
        flex-grow: 1;
        border: 0;
        font-family: $InterRegular;
        font-size: 14px;
        line-height: 1.4;
        color: var(--grey-4);
        height: 45px;
        &:focus {
          outline: none;
        }
      }
      .canEdit {
        display: flex;
        align-items: center;
        span {
          font-family: $InterRegular;
          font-size: 14px;
          line-height: 1.4;
          color: var(--grey-1);
        }
        img {
          width: 10px;
          margin-left: 14px;
        }
      }
      .foundList {
        border: 1.5px solid var(--screenz-main);
        position: absolute;
        top: 46px;
        left: 0;
        width: 100%;
        background: #fff;
        border-radius: 5px;
        padding: 0 15px;
        max-height: 300px;
        overflow: auto;
        .avatarAndName {
          display: flex;
          @extend %avatarAndName;
        }
      }
    }
    .accessMessage {
      display: flex;
      align-items: center;
      .usersIcon {
        margin-right: 17px;
      }
      .message {
        font-family: $InterRegular;
        font-size: 14px;
        line-height: 1.4;
        color: var(--grey-4);
      }
    }
  }

  .usersList {
    margin: 0 -5px;
    min-height: 250px;
    max-height: 300px;
    overflow: auto;
    .avatarAndName {
      @extend %avatarAndName;
    }
  }
}
