.tempCreds {
  position: absolute;
  bottom: -64px;
  // bottom: -20px;
  h4 {
    margin-bottom: 10px;
  }
  .select {
    user-select: all;
    display: inline-block;
  }
}
