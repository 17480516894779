@import 'sass/fontFamilies.scss';

.headerWrapper {
  padding: 23px 20px;
  background: #615ae2;
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  .title {
    font-size: 21px;
    font-family: $InterMedium;
    color: #fff;

    @media screen and (max-width: 500px) {
      font-size: 16px;
    }
  }
  .popoverWrapper {
    display: flex;
    align-items: center;
    @media screen and (min-width: 800px) {
      display: none;
    }
    .mobileDropdownBtn {
      right: 0;
      top: 17px;
      border-radius: 8px;
    }
    .playlistDropdown {
      padding: 5px 0;
      border-radius: 8px;
      width: 168px;
      .option {
        display: flex;
        align-items: center;
        padding: 10px;
        img {
          margin-right: 10px;
          width: 20px;
          height: 20px;
        }
        span {
          font-family: $InterRegular;
          font-size: 14px;
          color: var(--grey-1);
        }
      }
    }
  }
  .addScreensBtn {
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    font-family: $InterMedium;
    display: none;
    @media screen and (min-width: 800px) {
      // display: block;
    }
  }
}
