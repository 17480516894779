@import "sass/fontFamilies.scss";

.tableHead {
    border: 1px solid var(--grey-6);
    border-radius: 10px;
    width: 100%;
    margin-bottom: 5px;
    background: var(--white);
    thead {
        tr {
            grid-template-columns: 43% 21% 17% 19%;
            display: grid;
            padding: 15px 40px;
            border-bottom: 1px solid var(--grey-6);
            th {
                text-align: left;
                display: flex;
                align-items: center;
                white-space: nowrap;
                font-family: $InterRegular;
                font-size: 14px;
                font-weight: 500;
                color: #7f8fa4;
                margin-left: 10px;
            }
        }
    }
    tbody {
        display: block;
        overflow: auto;
        max-height: 400px;
        max-height: fit-content;
        margin-bottom: 10px;
        tr {
            grid-template-columns: 41% 20% 15% 24%;
            display: grid;
            padding: 15px 10px;
            border-bottom: 1px solid var(--grey-6);
            cursor: pointer;
            transition: 200ms;
            &.active {
                background: var(--screenz-main-hover);
            }
            &:hover {
                background: var(--screenz-main-hover);
            }
            td {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                color: var(--grey-3);
                font-size: 14px;
                font-family: $InterRegular;
                margin-left: 35px;
                &.sentDetails {
                    font-size: 14px;
                    text-align: left;
                    color: var(--grey-2);
                }
            }
        }
    }
}
