.primaryDetailsSkeleton {
  padding: 15px;
  width: 100%;
  .headSection {
    height: 50px;
    margin-bottom: 40px;
  }
  .headSeaction2 {
    height: 33%;
    width: 67%;
    display: block;
    margin: auto;
    max-width: 300px;
    max-height: 300px;
    margin-bottom: 30px;
  }
  .details {
    height: 30px;
    display: block;
  }
}
