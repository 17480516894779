@import 'sass/fontFamilies.scss';

.company {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  font-family: $InterRegular;
  color: #111216;
  padding: 15px 10px;
  border-bottom: 1px solid #d8dae9;
  p {
    flex-grow: 1;
    padding: 4px 0;
    margin-right: 12px;
  }
  &.active {
    color: #615ae2;
    font-family: $InterMedium;
  }
}
