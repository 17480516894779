@import 'sass/fontFamilies.scss';

.emptyPlaylist {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 9vh;
  .playlistEmptyStateImage {
    width: 194px;
    margin-bottom: 10px;
  }
  .heading {
    font-family: $InterMedium;
    font-size: 15px;
    font-weight: 500;
    color: var(--grey-1);
    margin-bottom: 25px;
  }
  .description {
    font-family: $InterRegular;
    font-size: 14px;
    color: #354052;
    max-width: 315px;
    text-align: center;
    margin-bottom: 33px;
  }
  .addContent {
    font-family: $InterMedium;
    font-size: 15px;
    font-weight: 500;
  }
}
