@import 'sass/fontFamilies.scss';

.teamMembersPermissions {
  height: 100%;
  .section {
    overflow: auto;
    height: 90%;
    padding: 0 30px;
    .sectionHeading {
      font-family: $InterMedium;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.32px;
      color: var(--grey-1);
      padding: 20px 0;
    }
  }
}
