@import 'sass/fontFamilies.scss';

.timeAndRefresh {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: var(--grey-bg);
  padding: 16px 10px;
  @media screen and (min-width: 800px) {
    padding: 0;
  }
  .pingTime {
    color: var(--bluey-grey);
    font-family: $InterRegular;
    margin-right: 12px;
    font-size: 11px;
    @media screen and (min-width: 800px) {
      font-size: 14px;
    }
  }
  .refreshIcon {
    width: 40px;
    height: 40px;
    border: 1px solid var(--grey-5);
    padding: 8px;
    background: var(--grey-7);
    border-radius: 8px;
    cursor: pointer !important;

    img {
      &.refreshAnimation {
        animation: rotate 1s linear infinite;
        @keyframes rotate {
          to {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}
