@import 'sass/fontFamilies.scss';

.progressWrapper {
  display: flex;
  // align-items: flex-end;
  grid-gap: 5px;
  align-items: center;
  .progressBar {
    flex-grow: 1;
    border-radius: 10px;
    background-color: #e7e6fb;
    height: 12px;
    position: relative;
    .progress {
      position: absolute;
      left: 0;
      top: 0;
      transition: 400ms;
      background: var(--screenz-main);
      // width: 51%;
      transition: 400ms;
      height: 12px;
      border-radius: 8px;
    }
  }
  .size {
    font-family: $InterRegular;
    font-size: 13px;
    color: #7f8fa4;
  }
}
