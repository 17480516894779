@import 'sass/fontFamilies.scss';

.roleWrapper {
  height: 100%;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  .componentHeading {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .heading {
      font-family: $InterMedium;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: -0.3px;
      color: var(--grey-1);
    }
    .addRoleBtn {
      font-family: $InterMedium;
      font-size: 15px;
      font-weight: 500;
      color: var(--white);
    }
  }
}
