@import 'sass/fontFamilies.scss';

.file {
  display: flex;
  border: var(--border);
  padding: 12px;
  &.offModal {
    display: grid;
    grid-template-columns: 4fr 3fr 1fr;
    padding: 5px 35px;
    grid-gap: 20px;
    @media screen and (max-width: 800px) {
      grid-template-columns: 11fr 1fr;
      padding-right: 0;
    }
  }

  &.inModal {
    border-radius: 8px;
    margin-bottom: 10px;
  }
  .main {
    display: flex;
    align-items: flex-start;
    flex-grow: 1;
    @media screen and (max-width: 800px) {
      &:first-child {
        grid-column: 1/4;
      }
    }
    @media screen and (max-width: 800px) {
      border-bottom: 1px solid #d8dae9;
      padding-bottom: 6px;
    }
    &.offModal {
      align-items: center;
    }
    .preview {
      background: #c4c4c4;
    }
    .cancelIcon {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    .fileMeta {
      margin-left: 10px;
      &.inModal {
        // width: 55vw;
        width: 85%;
        @media screen and (min-width: 500px) {
          width: 65vw;
        }
        @media screen and (min-width: 800px) {
          max-width: 400px;
        }
      }
      .nameAndSize {
        display: flex;
        .name {
          font-family: $InterRegular;
          font-size: 13px;
          color: #33373a;
          margin-right: 3px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @media screen and (min-width: 786px) {
            max-width: 12vw;
          }
          @media screen and (min-width: 1200px) {
            max-width: 20vw;
          }
        }
        .size {
          font-family: $InterRegular;
          font-size: 13px;
          color: #7f8fa4;
        }
      }
      .contentMeta {
        font-family: $InterRegular;
        font-size: 13px;
        color: #7f8fa4;
        span {
          margin: 0 1px;
        }
      }
    }
  }
  .cancel {
    display: flex;
    align-items: center;
    span {
      font-family: $InterRegular;
      font-size: 13px;
      color: var(--grey-1);
    }
    img {
      cursor: pointer;
      margin-left: 3px;
      width: 24px;
      height: 24px;
    }
  }
}
