@import 'sass/fontFamilies.scss';

.content {
  padding: 5px 20px;
  // grid-template-columns: 55% 10% 20% 13% 3%;
  grid-template-columns: 50% 12% 20% 15% 5%;
  align-items: center;
  border-bottom: var(--border);
  cursor: pointer;
  display: flex;
  @media screen and (min-width: 800px) {
    display: grid;
    border-bottom: var(--border);
  }
  @media screen and (max-width: 800px) {
    padding: 5px 20px;
    display: grid;
    grid-template-columns: 90% 10%;
    // width: 87vw;
  }
  &:hover {
    background: var(--screenz-main-hover);
  }
  .checkbox {
    margin-right: 8px;
  }

  &:last-child {
    // border-bottom: none;
    @media screen and (max-width: 800px) {
      // display: grid;
      border-bottom: none;
    }
  }
  .previewAndMeta {
    display: flex;
    align-items: center;
    @media screen and (max-width: 800px) {
      flex-grow: 1;
    }
    .contentNameAndLength {
      width: calc(100% - 80px);

      .contentName {
        font-family: $InterRegular;
        font-size: 13px;
        color: #33373a;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 90%;
        display: block;
        @media screen and (max-width: 800px) {
          max-width: 40vw;
        }
      }
      .contentMeta {
        font-family: $InterRegular;
        font-size: 13px;
        color: #33373a;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 90%;
        display: block;
      }
      .url {
        font-family: $InterRegular;
        font-size: 13px;
        color: #33373a;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 90%;
        display: block;
        @media screen and (max-width: 800px) {
          max-width: 40vw;
        }

        // p {
        //   white-space: nowrap;
        //   overflow: hidden;
        //   text-overflow: ellipsis;
        //   width: 75%;
        // }
      }

      > p {
        // max-width: 250px;
        width: 75%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // @media screen and (max-width: 799px) {
        //   width: 50vw;
        // }
      }
      span {
        color: #7f8fa4;
        @media screen and (max-width: 800px) {
          width: 100px !important;
          overflow-x: hidden;
        }
      }
    }
  }
  .type,
  .ownerName,
  .modifiedOn {
    text-transform: capitalize;
    font-family: $InterRegular;
    font-size: 14px;
    color: #707289;
    display: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media screen and (min-width: 800px) {
      display: unset;
    }
  }
  .modifiedOn {
    color: var(--grey-2);
  }

  .morePopupContainer {
    .popover {
      right: 0;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    }
    .morePopup {
      right: 0;
      display: flex;
      // align-items: center;
      flex-direction: column;
      padding: 10px;
      font-family: $InterRegular;
      font-size: 14px;
      line-height: 1.36;
      color: var(--grey-1);

      .option {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
        img {
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }
      }
    }
    .moreBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 300ms;
      .icon {
        path {
          fill: var(--grey-4);
        }
      }
      &:hover {
        background: var(--grey-6);
        border-radius: 5px;
        .icon {
          path {
            fill: var(--grey-3);
          }
        }
      }
    }
  }
}
