.headerContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  .cancelBtn {
    background: transparent;
    color: #8a8daa;
    margin-right: 10px;
    border-color: #acaec5;
    &:hover {
      color: white;
    }
  }
}
