@import 'sass/fontFamilies.scss';

.details {
  background: var(--white);
  height: 100vh;
  .header {
    padding: 11px 15px;
    background: var(--grey-7);
    // border-left: 1px solid var(--grey-6);
    border-bottom: 1px solid var(--grey-6);
    height: 60px;
    .screenBtn {
      padding: 10px;
      font-family: $InterMedium;
    }
  }
  .screenNameAndHashCode {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 15px;
    padding: 15px 15px 25px 15px;
    border-bottom: 1px solid var(--grey-6);
    .screenName,
    .disabled {
      p {
        margin-bottom: 10px;
        font-size: 13px;
        font-family: $InterRegular;
        color: var(--grey-3);
      }
      input {
        background: var(--white);
        color: var(--grey-1);
      }
    }
    .disabled {
      input {
        background: var(--grey-bg);
        &:disabled {
          color: var(--bluey-grey);
        }
      }
    }
  }
  .contentSection {
    padding: 15px;
    @media screen and (max-width: 800px) {
      border-bottom: 1px solid var(--grey-6);
    }
    .sectionHeading {
      font-family: $InterMedium;
      font-size: 15px;
      font-weight: 500;
      color: var(--grey-1);
      margin-bottom: 12px;
    }
    .content {
      border: 1px solid var(--grey-6);
      padding: 18px 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;
      cursor: pointer;
      margin-bottom: 15px;
      .nameSection {
        display: flex;
        align-items: center;
        .contentPreview {
          margin-right: 10px;
          width: 30px;
          height: 30px;
          > img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        .contentName {
          font-size: 14px;
          color: var(--grey-1);
          font-family: $InterRegular;
        }
      }
      .timeSection {
        display: flex;
        align-items: center;
        .clockIcon {
          margin-right: 6px;
        }
        .duration {
          font-family: $InterRegular;
          font-size: 14px;
          color: var(--grey-1);
          margin-right: 16px;
        }
        @media screen and (max-width: 800px) {
          .clockIcon,
          .duration {
            display: none;
          }
        }
      }
    }
    .changeContentBtn {
      background: var(--grey-7);
      color: var(--grey-1);
      font-size: 14px;
      font-weight: 500;
      font-family: $InterMedium;
      padding: 10px;
    }
  }
  .shareAccess {
    padding: 18px 12px;
  }
}
