@import 'sass/fontFamilies.scss';

.addTeammate {
  max-width: 460px;
  @media screen and (min-width: 800px) {
    // max-width: 460px;
  }
  @media screen and (max-width: 1020px) and (orientation: landscape) {
    // width: 375px;
  }
  .role {
    padding-left: 0;
    padding-top: 15px;
    height: 19px;
    flex-grow: 0;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    border: none;
    color: #111216;
  }

  .divider {
    max-width: 460px;
    flex-grow: 2;
    border-bottom: 2px solid #f0f3f8;
    margin: 0 -20px 17px;
    padding: 10px 0;
  }

  .desc {
    margin-bottom: 10px;

    @media screen and (min-width: 800px) {
      width: 152px;
    }
    @media screen and (max-width: 1020px) and (orientation: landscape) {
    }

    .label {
      font-size: 11px;
      font-family: $InterRegular;
      margin-bottom: 10px;
      color: #3c3d48;
    }

    .input {
      font-size: 14px;
      height: 46px;
      font-family: $InterRegular;
      background: transparent;
      color: #8a8daa;
    }
  }
  .labelAndInput {
    margin-bottom: 10px;

    .label {
      font-size: 24px;
      font-family: $InterRegular;
      margin-bottom: 10px;
      color: #5b5d70;
    }

    input {
      font-size: 14px;
      font-family: $InterRegular;
      background: #ffffff;
      color: #8a8daa;
    }

    .inputSelect {
      font-size: 14px;
      height: 46px;
      width: 100%;
      font-family: $InterRegular;
      background: transparent;
      color: #8a8daa;
      border-radius: 5px;
      background: #ffffff;
      border: 1px solid #aaadc4;
      // color: #aaadc4;
      border-radius: 5px;
      padding: 10px;
      // width: 100%;
      background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
        no-repeat;
      background-position: calc(100% - 0.75rem) center !important;
      -moz-appearance: none !important;
      -webkit-appearance: none !important;
      appearance: none !important;
      padding-right: 2rem !important;

      &:focus {
        outline: none;
      }

      @media screen and (min-width: 800px) {
        // max-width: 460px;
        width: 259px;
      }
      @media screen and (max-width: 1020px) and (orientation: landscape) {
      }
    }
  }

  .flex {
    // display: flex;
    width: 100%;

    @media screen and (min-width: 800px) {
      // max-width: 460px;
      display: flex;
    }
    @media screen and (max-width: 1020px) and (orientation: landscape) {
      width: 375px;
    }
  }
}
