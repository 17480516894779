.logo {
  height: 24.3px;
  width: 35.6px;
  border-radius: 5px;
  &.sidebar {
    width: 35.6px;
    height: 25px;
    path {
      fill: #acaec5;
    }
  }
  &.onboarding {
    width: 45px;
    height: 30px;
  }
}
