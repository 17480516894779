@import 'sass/fontFamilies.scss';

.profilePictureWrapper {
  padding: 20px 10px;
  background: var(--white);
  border-bottom: var(--border);
  margin-bottom: 65px;
  @media screen and (min-width: 800px) {
    padding: 0;
    border: 0;
    margin: 0;
  }
  .sectionHeading {
    @media screen and (min-width: 800px) {
      display: none;
    }
  }
  .description {
    font-family: $InterRegular;
    font-size: 13px;
    color: var(--grey-3);
    margin-bottom: 14px;
  }
  .popoverWrapper {
    margin-bottom: 20px;
    .choosedOption {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 13px 10px;
      border: var(--border);
      border-radius: 8px;
      cursor: pointer;
      > p {
        font-family: $InterRegular;
        font-size: 14px;
        color: var(--bluey-grey);
      }
      .caretLeft {
        transform: rotate(90deg);
        width: 17px;
      }
    }
    .popover {
      width: 100%;
      border-radius: 0 0 8px 8px;
      .listContainer {
        list-style: none;

        .list {
          font-family: $InterRegular;
          font-size: 14px;
          padding: 13px 10px;
        }
      }
    }
  }

  .saveChanges {
    font-family: $InterMedium;
    font-size: 15px;
    font-weight: 500;
    @media screen and (min-width: 800px) {
      display: none;
    }
  }
}
