@import 'sass/fontFamilies.scss';

.clientCard {
  border: 1px solid #d8dae9;
  border-radius: 5px;
  margin-top: 20px;
  &:hover {
    border: 1.2px solid #828282;
  }
  .header {
    border-bottom: 1px solid #d8dae9;
    background: #fff;
    padding: 15px;
    border-radius: 5px 5px 0 0;
    .headerContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .clientName {
        color: #111216;
        font-size: 14px;
        font-weight: 500;
        font-family: $InterMedium;
        margin-bottom: 10px;
      }
      .morePopover {
        border-radius: 8px;
        right: -20px;
        top: 33px;
        .moreWrapper {
          min-width: 180px;
          border: 1px solid var(--grey-5);
          border-radius: inherit;
          .moreOption {
            display: flex;
            align-items: center;
            padding: 10px 15px;
            cursor: pointer;
            img {
              margin-right: 5px;
            }
            font-family: $InterRegular;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.4;
            color: var(--grey-1);
            &:hover {
              background: var(--screenz-main-hover);
              border-radius: inherit;
            }
          }
        }
        .caretContainer {
          border-style: solid;
          border-width: 0px 10px 10px 10px;
          border-top-color: transparent;
          border-left-color: transparent;
          border-bottom-color: #d0d0d0;
          border-right-color: transparent;
          width: 0px;
          height: 0px;
          display: block;
          position: absolute;
          bottom: 133px;
          right: 20px;
          span {
            display: block;
            border-style: solid;
            border-width: 0px 10px 10px 10px;
            border-top-color: transparent;
            border-left-color: transparent;
            border-bottom-color: white;
            border-right-color: transparent;
            width: 0px;
            height: 0px;
            margin: 1px -10px -9px;
          }
        }
      }
      .moreBtn {
        visibility: hidden;
        padding: 6px 0px;
        font-family: $InterRegular;
        border: none;
        margin-bottom: 10px;

        @media screen and (max-width: 800px) {
          // opacity: 1;
          visibility: visible;
        }
        &:hover {
          opacity: 1;
          background-color: var(--grey-7);
        }

        &.activeMore {
          opacity: 0.75;
          background-color: var(--grey-7);
        }
      }
    }
    .statusContainer {
      display: flex;
      justify-content: space-evenly;
      grid-gap: 15px;
      .status {
        border-radius: 10px;
        @media screen and (min-width: 800px) {
          min-width: 135px;
        }
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        &.online {
          background: #e3f5e6;
        }
        &.offline {
          background: #f0f3f8;
        }
        .statusIcon {
          height: 40px;
          width: 40px;
          border-radius: 100%;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          &.connected {
            background: #36af47;
          }
          &.disconnected {
            background: #acaec5;
          }
        }
        .count {
          font-size: 28px;
          font-weight: 500;
          font-family: $InterMedium;
          color: #000000;
        }
        .message {
          font-size: 15px;
          font-family: $InterRegular;
          &.online {
            color: #739a78;
          }
          &.offline {
            color: #8a8daa;
          }
        }
      }
    }
  }
  .header:hover .moreBtn {
    visibility: visible;
  }
  .footer {
    padding: 15px;
    border-radius: 0 0 5px 5px;
    display: flex;
    background: #f9fafb;
    justify-content: space-between;

    &.superUserAdmin {
      justify-content: flex-end;
    }

    .clientsCount {
      font-family: $InterRegular;
      font-size: 13px;
      span:nth-child(1) {
        color: #8a8daa;
      }
      span:nth-child(2) {
        color: #111216;
      }
    }
    .gotoClient {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 13px;
      color: #8a8daa;
      font-family: $InterRegular;
      > img {
        margin-left: 5px;
      }
    }
    .clientPlan {
      display: flex;
      align-items: center;
      p:nth-child(1) {
        font-size: 13px;
        font-family: $InterRegular;
        color: #8a8daa;
        margin-right: 5px;
      }
      p:nth-child(2) {
        font-size: 14px;
        font-weight: 500;
        font-family: $InterMedium;
        color: #ffffff;
        border-radius: 5px;
        background: #acaec5;
        padding: 4px 8px;
      }
    }
  }
}
