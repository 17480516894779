.contentWrapper {
  background: #f4f5f7;
  display: flex;
  flex-direction: column;
  height: 100%;
  // min-height: 100%;
  // padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  .notFound {
    text-align: center;
    padding: 30px;
    font-weight: 500;
    font-family: 'Inter-Medium';
  }
  .responsiveHide {
    @media screen and (min-width: 800px) {
      display: none;
    }
  }
}
