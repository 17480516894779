@import 'sass/fontFamilies.scss';

.emptyStates {
  overflow: hidden;
  height: 100%;
  .subHeader {
    height: 60px;
    min-height: 60px;
    max-height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
    border-bottom: var(--border);
    font-family: $InterMedium;
    font-size: 16px;
    font-weight: 500;
    color: var(--grey-1);
  }
  .pageWrapper {
    padding: 0 10px;
    @media screen and (min-width: 800px) {
      width: 75%;
    }
    margin: auto;
    padding-top: 20px;
    .heading {
      font-family: $InterMedium;
      font-size: 15px;
      font-weight: 500;
      color: var(--grey-1);
      margin-bottom: 20px;
    }
  }
}
