@import 'sass/fontFamilies.scss';

.popoverWrapper {
  .selectedOption {
    background: var(--white);
    font-family: $InterMedium;
    font-size: 14px;
    font-weight: 500;
    color: var(--grey-1);
    padding: 20px;
    border-bottom: var(--border);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    .caretLeft {
      transform: rotate(90deg);
      width: 17px;
    }
  }
  .popover {
    width: 100%;
    padding: 30px 20px;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.2);
  }
}
