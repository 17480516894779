@import 'sass/fontFamilies.scss';

.clientsDropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 7px;
  border-radius: 8px;
  @media screen and (max-width: 800px) {
    display: none;
  }

  &:hover {
    background: #403aa5;
  }
  // &.shown {
  //   background: #3b369a;
  // }
  .clientName {
    color: #fffcef;
    font-size: 13px;
    font-family: $InterRegular;
  }
  .expandArrowDown {
    margin: 0 0 0 5px;
    transition: 400ms;
  }
  .shown {
    transform: rotate(90deg);
  }
}
.dropdown {
  border-radius: 8px;
  min-width: 300px;

  left: 20px;
  .searchContainer {
    border-bottom: 1px solid #acaec5;
    padding: 10px;
  }
  .listContainer {
    max-height: 350px;
    overflow: auto;
    .listItem {
      padding: 10px;
      cursor: pointer;
      font-size: 14px;
      color: #111216;
      font-family: $InterRegular;
      list-style-type: none;
      line-height: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: 400ms;
      &:hover {
        color: #615ae2;
        background: #f5f5fd;
      }
      &.active {
        color: #615ae2;
        background: #f5f5fd;
        font-weight: 500;
        font-family: $InterMedium;
      }
      .activeClientIcon {
        width: 24px;
        height: 24px;
      }
    }
  }
}
