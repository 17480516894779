@import "sass/fontFamilies.scss";
.playlists {
    margin: 0 -10px;
    background: var(--white);
    width: 100%;
    .avatarContainer {
        display: flex;
        padding: 0 15px;
        margin: 0 -10px;
        .avatarCircle {
            width: 36px;
            height: 36px;
            background-color: #d8dae9;
            text-align: center;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            margin: 10px 5px 10px 10px;
            .avatarText {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 36px;
                height: 36px;
                font-family: $InterRegular;
                font-size: 14px;
                color: #ffffff;
                > span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                  img {
                    height: 100%;
                    width: 100%;
                    object-fit: fill;
                    border-radius: 100%;
                    box-shadow: none;
                  }

            }
        }
        .nameCotainer{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .span,
            .nameDetails {
                font-size: 14px;
                font-family: $InterRegular;
                line-height: 1.4;
                font-weight: normal;
                color: #111216;
                margin-top: 10px;
            }
    
        }
        .emailDetails {
            font-size: 13px;
            font-family: $InterRegular;
            text-align: left;
            color: #8a8daa;
        }
    }
    .dividerRow {
        margin-left: 15%;
        border-top: 1px solid var(--grey-6);
        margin-right: -10px;

    }
    .permissionContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // border-bottom: 1px solid var(--grey-6);
        .fullAccess {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-left: 12%;
            .permissionDetails {
                margin: 10px 5px 10px 10px;
                .permissionTitle {
                    position: relative;
                    font-family: $InterRegular;
                    font-size: 11px;
                    font-weight: 500;
                    color: #7c8fa6;
                }
                .permissionAccess {
                    position: relative;
                    font-family: $InterRegular;
                    font-size: 11px;
                    font-weight: 500;
                    color: #5b5d70;
                }
            }
        }
        .notSetupContainer {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .fA {
                position: relative;
                font-family: $InterRegular;
                font-size: 11px;
                font-weight: 500;
                color: #7c8fa6;
                margin-right: 2px;
            }
            .notSetupBtn {
                color: #8a8daa;
                border-color: #8a8daa;
            }
        }
    }
    .actionContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--grey-6);
        margin-right: -10px;
        .actionButtonsContainer {
            margin: 10px 5px 10px 10px;
            .actionTitle {
                position: relative;
                font-family: $InterRegular;
                font-size: 11px;
                font-weight: 500;
                color: #7c8fa6;
                margin-left: 25%;
            }
            .actionButtons {
                margin-left: 20%;
                margin-top: 5px;
            }
        }
    }
    @media screen and (min-width: 769px) {
        display: none;
    }
}
