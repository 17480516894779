$InterThin: 'Inter-Thin';
$InterSemiBold: 'Inter-SemiBold';
$InterRegular: 'Inter-Regular';
$InterMedium: 'Inter-Medium';
$InterLight: 'Inter-Light';
$InterExtraLight: 'Inter-ExtraLight';
$InterExtraBold: 'Inter-ExtraBold';
$InterBold: 'Inter-Bold';
$InterBlack: 'Inter-Black';

$HelveticaRegular: 'Helvetica';
$HelveticaBold: 'Helvetica-Bold';
$HelveticaBoldOblique: 'Helvetica-BoldOblique';
$helveticaCompressed: 'helvetica-compressed';
$helveticaLight: 'helvetica-light';
$HelveticaOblique: 'Helvetica-Oblique';
$helveticaRoundedBold: 'helvetica-rounded-bold';

$TTInterphasesExtraLight: 'TTInterfaces-ExtraLight';
$TTInterphasesLight: 'TTInterfaces-Light';
$TTInterphasesMedium: 'TTInterfaces-Medium';
$TTInterphasesRegular: 'TTInterfaces-Regular';
$TTInterphasesBold: 'TTInterfaces-Bold';
$TTInterphasesBlack: 'TTInterfaces-Black';
