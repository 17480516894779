@import 'sass/fontFamilies.scss';

.listAndDetails {
  height: 100%;
  overflow: hidden;

  @media screen and (max-width: 800px) {
    margin-bottom: 5rem;
    // width: 100vw;
  }
  @media screen and (min-width: 800px) {
    display: grid;
    height: 100%;
    grid-template-columns: 1fr;
    &.displayboth {
      grid-template-columns: 3fr 1fr;
    }
  }
  .foldersAndScreensWrapper {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    grid-auto-rows: minmax(min-content, max-content);
    overflow-x: hidden;
    .foldersWrapper {
      margin: 20px;
      display: flex;
      flex-wrap: wrap;
      grid-gap: 10px;
      margin-bottom: 15px;

      @media screen and (max-width: 800px) {
        // width: 0vw;
      }
      &.additional {
        padding: 15px 20px 0 20px;
      }
    }
    .listContainer {
      border: var(--border);
      background: var(--white);
      border-radius: 10px;

      margin: 15px 20px;

      padding-bottom: none;
      @media screen and (min-width: 800px) {
        padding-bottom: 6px;
        border-radius: 10px;
      }

      .listHeader {
        grid-template-columns: 50% 12% 20% 13% 2%;
        border-bottom: var(--border);
        display: none;
        padding: 0 20px;
        @media screen and (min-width: 800px) {
          display: grid;
        }
        p {
          font-family: $TTInterphasesMedium;
          color: #7f8fa4;

          font-size: 14px;
          font-weight: 500;
          padding: 20px 0;
        }
        p:nth-child(1) {
          padding-left: 80px;
        }
      }
      .uploadingFilesContainer {
        background: var(--grey-bg);
        @media screen and (min-width: 800px) {
          padding-top: 0;
        }
      }
    }
    @media screen and (max-width: 800px) {
      display: none;
      width: 100vw;
      // overflow-x: hidden;
      &.Content {
        display: grid;
      }
    }
  }
  .detailView {
    height: 100%;
    margin-top: -1.5px;

    @media screen and (max-width: 800px) {
      display: none;
      &.ContentDetails {
        display: flex;
      }
    }
  }
}
