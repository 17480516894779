@import "sass/fontFamilies.scss";

.subFolderEmptyState {
  padding: 10px 20px 10px 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  .emptyScreensFoldersBanner {
    margin-top: 3vh;
    margin-bottom: 25px;
    width: 70%;
    max-width: 365px;
    @media screen and (min-width: 800px) {
      margin-top: 7vh;
    }
  }
  .noScreens {
    font-family: $InterRegular;
    font-size: 21px;
    font-weight: 500;
    color: #33373a;
    text-align: center;
    margin-bottom: 5px;
  }
  .description {
    font-family: $InterRegular;
    font-size: 16px;
    color: #33373a;
    text-align: center;
  }
}
