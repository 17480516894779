@import 'sass/fontFamilies.scss';

.resendContainer {
  display: flex;
  .resendBtn {
    display: flex;
    font-family: $InterRegular;
    justify-content: center;
    font-weight: 500;
    font-size: 11px;
    color: var(--grey-1);
    align-items: center;
    border-radius: 8px;
    background-color: var(--grey-7);
    border: 0;
    padding: 10px;
    height: 30px;
    margin-left: 10px;
    transition: 200ms;
    cursor: pointer;
    @media screen and (max-width: 800px) {
      padding: 7px 8px;
    }
    img {
      height: 16px;
      width: 16px;
    }
    &:hover {
      background-color: #615ae2;
      color: #ffff;
      path {
        fill: #fff;
      }
    }
  }
  .resendIcon {
    margin-right: 5px;
  }
  :disabled {
    color: var(--grey-1);
    background-color: var(--grey-7);
    path {
      fill: var(--grey-7);
    }
    &:hover {
      color: var(--grey-1);
      background-color: var(--grey-7);
      path {
        fill: var(--grey-7);
      }
    }
  }
}
