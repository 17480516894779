.btn {
  border-radius: 3px;
  border: 0;
  background-color: #5047e5;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 800px) {
  button {
    cursor: auto;
  }
}
button:active {
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.15);
}
.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}
.w-100 {
  width: 100%;
}
/* Flex-classes */
.flex {
  display: flex;
}
.flex-grow {
  flex-grow: 1;
}
.flex-col {
  flex-direction: column;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.align-items-center {
  align-items: center;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-space-between {
  justify-content: space-between;
}

.align-self-end {
  align-self: flex-end;
}

/* Padding-classes */
.p1 {
  padding: 5px;
}
.p2 {
  padding: 10px;
}
.p3 {
  padding: 15px;
}
.p4 {
  padding: 20px;
}
.p5 {
  padding: 25px;
}

/* Margin-classes */
.m1 {
  margin: 5px;
}
.m2 {
  margin: 10px;
}
.m3 {
  margin: 15px;
}
.m4 {
  margin: 20px;
}
.m5 {
  margin: 25px;
}

/* Margin-top-classes */
.mt1 {
  margin-top: 5px;
}
.mt2 {
  margin-top: 10px;
}
.mt3 {
  margin-top: 15px;
}
.mt4 {
  margin-top: 20px;
}
.mt5 {
  margin-top: 25px;
}

/* Margin-right-classes */
.mr1 {
  margin-right: 5px;
}
.mr2 {
  margin-right: 10px;
}
.mr3 {
  margin-right: 15px;
}
.mr4 {
  margin-right: 20px;
}
.mr5 {
  margin-right: 25px;
}

/* Margin-bottom-classes */
.mb1 {
  margin-bottom: 5px;
}
.mb2 {
  margin-bottom: 10px;
}
.mb3 {
  margin-bottom: 15px;
}
.mb4 {
  margin-bottom: 20px;
}
.mb5 {
  margin-bottom: 25px;
}
.mb6 {
  margin-bottom: 30px;
}
.mb7 {
  margin-bottom: 35px;
}

/* Margin-left-classes */
.ml1 {
  margin-left: 5px;
}
.ml2 {
  margin-left: 10px;
}
.ml3 {
  margin-left: 15px;
}
.ml4 {
  margin-left: 20px;
}
.ml5 {
  margin-left: 25px;
}

.w-5 {
  width: 5%;
}
.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.w-30 {
  width: 30%;
}
.w-35 {
  width: 35%;
}
.w-45 {
  width: 45%;
}
.w-50 {
  width: 50%;
}
.w-70 {
  width: 50%;
}
