@import "sass/fontFamilies.scss";

.runningStatus {
  padding: 4px 8px;
  border-radius: 3px;
  background-color: var(--success);
  color: var(--white);
  font-family: $TTInterphasesMedium;
  font-size: 10px;
}
