@import 'sass/fontFamilies.scss';

.inviteUser {
  margin: 0 -15px;
  .header {
    padding: 15px;
    display: flex;
    border-bottom: var(--border);
    .iconContainer {
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
      background: #7973e6;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 7px;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .teamMeta {
      .heading {
        font-family: $InterMedium;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.32px;
        color: var(--grey-1);
        margin-bottom: 5px;
      }
      .description {
        font-family: $InterMedium;
        font-size: 11px;
        font-weight: 500;
        color: var(--grey-3);
      }
    }
  }

  .roleSection {
    padding: 15px;

    .rolesHeader {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      img {
        width: 22px;
        height: 24px;
        margin-right: 8px;
      }
      p {
        font-family: $InterMedium;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: -0.3px;
        color: var(--grey-1);
      }
    }
    .rolesAndDescription {
      display: flex;
      justify-content: space-between;
      display: grid;
      grid-template-columns: 40% 50%;
      margin-bottom: 30px;
      .descriptions {
        p {
          font-family: $InterMedium;
          font-size: 11px;
          font-weight: 500;
          color: var(--grey-3);
          &:nth-child(1) {
            margin-bottom: 10px;
          }
        }
      }
      .rolesDropdown {
        position: relative;
        .selectedOption {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          height: 46px;
          border: 1.6px solid #d8dae9;
          border-radius: 8px;
          cursor: pointer;
          p {
            font-family: $InterRegular;
            font-size: 14px;
            line-height: 1.4;
            color: var(--grey-4);
          }
          img {
            width: 10px;
          }
        }
        .optionsContainer {
          position: absolute;
          top: 45px;
          width: 100%;
        }
      }
    }
  }
  .footerSection {
    border-top: 1.5px solid var(--grey-6);
    padding: 15px;
    display: flex;
    justify-content: flex-end;
    .sendInviteBtn {
      font-family: $InterMedium;
      font-size: 15px;
      font-weight: 500;
      color: var(--white);
    }
  }
}
