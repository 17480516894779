@import 'sass/fontFamilies.scss';

.labelAndInput {
  width: 100%;
  position: relative;
  .errorInformationIcon {
    position: absolute;
    right: 13px;
    top: 31px;
  }
  .label {
    margin-bottom: 5px;
    font-family: $InterRegular;
    font-size: 13px;
    color: #797a8b;
    &.error {
      color: var(--fail);
    }
  }
  .input {
    font-family: $InterRegular;
    font-size: 14px;
    color: var(--grey-1);
    &.errorExist {
      margin-bottom: 10px;
    }
  }
  .errorMessage {
    font-family: $InterRegular;
    font-size: 13px;
    color: var(--fail) !important;
    transition: 400ms;
    overflow: hidden;
    &.inActive {
      max-height: 0;
      opacity: 0;
    }
    &.active {
      max-height: 40px;
      opacity: 1;
    }
  }
}
