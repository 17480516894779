@import 'sass/fontFamilies.scss';

.screen {
  border-bottom: 0px;
  border-radius: 10px 0 0 0;
  background: #f4f5f7;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d8dae9;
  border-bottom: 0;
  width: 147px;
  user-select: none;
  &.noScreenShot {
    padding: 10px;
  }

  @media screen and (min-width: 800px) {
    width: auto;
    border-radius: 10px 10px 0 0;
    min-height: 175px;
    flex-basis: 40%;
  }
  .screenshot {
    width: 100%;
    border-radius: 10px 0 0 0;
    height: 83px;
    object-fit: cover;
    @media screen and (min-width: 800px) {
      border-radius: 10px 10px 0 0;
      min-height: 175px;
    }
  }
  .connectionStatus {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: 500;
    font-family: $InterMedium;
    color: #5b5d70;
    max-width: 145px;
    text-align: center;
    .svg {
      margin-bottom: 5px;
      width: 41px;
      height: 41px;
      @media screen and (max-width: 800px) {
        max-width: 25px;
        width: 7vw;
        height: auto;
      }
      path {
        fill: #5b5d70;
      }
    }
  }
}
