@import 'sass/fontFamilies.scss';

.wrapper {
  bottom: 20px;
  z-index: 1;
  @media screen and (min-width: 800px) {
    position: relative;
    position: fixed;
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: 400ms;
    cursor: pointer;
    @media (max-width: 800px) {
      margin: 0;
      width: 80px;
    }
    @media (orientation: landscape) and (max-width: 810px) {
      margin: 0;
    }
    &:hover {
      .iconHolder {
        @media (min-width: 800px) {
          background: #615ae2;
        }
        .svgStyle {
          path {
            @media (max-width: 800px) {
              fill: #615ae2;
            }
            fill: #fff;
          }
        }
      }
      .name {
        color: #111216;
      }
    }
    .iconHolder {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 100%;
      transition: 400ms;
      @media (min-width: 800px) {
        width: 40px;
        height: 40px;
        background: #f0f3f8;
      }
      @media (orientation: landscape) and (max-width: 810px) {
        width: 30px;
        height: 30px;
        background: #f0f3f8;
      }
      &.active {
        @media (min-width: 800px) {
          background: #615ae2;
        }

        .svgStyle {
          path {
            fill: #fff;
            @media (max-width: 800px) {
              fill: #615ae2;
            }
          }
        }
      }
      .svgStyle {
        @media (max-width: 800px) {
          width: 24px;
          height: 24px;
        }
        @media (orientation: landscape) and (max-width: 810px) {
          width: 20px;
          height: 20px;
        }
        path {
          fill: #acaec5;
        }
      }
    }
    .name {
      font-size: 11px;
      color: #8a8daa;
      font-family: $InterRegular;
      cursor: pointer;
      &.active {
        color: #111216;
      }
    }
  }

  .moreIcon {
    display: none;
    z-index: 100;
    @media (max-width: 800px) {
      display: flex;
    }
    @media (orientation: landscape) and (max-width: 810px) {
      display: flex;
    }
  }
}
.profileIcon {
  @media (max-width: 800px) {
    display: none;
  }
}

.morePopover {
  bottom: 11px;
  left: 26px;
  // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  min-width: 270px;
  border-radius: 5px;
  .avatar {
    border-radius: 0px;
  }
  .logout {
    border-radius: 5px;
  }
  .menu {
    display: flex;
    align-items: center;
    padding: 15px 18px;
    background: #fff;
    font-size: 14px;
    font-weight: 500;
    color: #111216;
    font-family: $InterMedium;
    border-bottom: 1px solid #d8dae9;
    cursor: pointer;
    &:hover {
      background: var(--screenz-main-hover);
    }
    img {
      margin-right: 5px;
    }
    p {
      flex-grow: 1;
    }
  }
  .companyWrapper {
    padding: 17px 20px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: var(--border);
    &:hover {
      background: var(--screenz-main-hover);
    }
    .companyAndOwner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .company {
        color: #615ae2;
        font-size: 14px;
        font-weight: 500;
        font-family: $InterMedium;
      }
      .owner {
        color: #8a8daa;
        font-size: 14px;
        font-weight: 500;
        font-family: $InterMedium;
      }
    }
  }
  .companiesPopover {
    min-width: 230px;
    max-height: 300px;
    right: -218px;
    bottom: 0;
    border-radius: 5px;
    overflow-y: auto;
    padding: 5px 0;
    :hover {
      background: var(--screenz-main-hover);
    }
    .company {
      cursor: pointer;
      border: 0;
      padding: 7px 16px;
    }
  }
}
