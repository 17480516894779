@import 'sass/fontFamilies.scss';

.subheader {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  height: 60px;
  background: transparent;
  border-bottom: 1px solid #d8dae9;
  @media screen and (max-width: 800px) {
    background: #fff;
    padding: 5px 10px;
  }
  @media screen and (max-width: 800px) {
    background: #fff;
    padding: 5px 10px;
  }
  .left {
    width: 50%;
    .clientName {
      font-size: 15px;
      font-weight: 500;
      font-family: $InterMedium;
      span {
        cursor: pointer;
        color: #6e6f72;
        &.active {
          color: #111216;
        }
      }
      .separator {
        margin: 0 3px 0 5px;
      }
    }
  }
  .right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .searchField {
      max-width: 375px;
      margin-right: 10px;
    }
    .screenzBtn,
    .folderBtn {
      font-size: 15px;
      font-weight: 500;
      font-family: $InterMedium;
      padding: 10px 12px;
    }
    .folderBtn {
      margin-right: 10px;
    }
    .DropdownBtn {
      direction: ltr;
      font-family: $InterMedium;
      font-size: 15px;
      font-weight: 500;
    }
    .popoverWrapper {
      direction: rtl;
      border-radius: 8px;
      margin-left: -3rem;
      @media screen and (max-width: 800px) {
        display: none;
      }
      .addDropdown {
        min-width: 170px;
        border: solid 1px var(--grey-6);
        direction: ltr;
        border-radius: 8px;
        // margin-top: -10px;
        background-color: #fff;
        .option {
          display: flex;
          align-items: center;
          padding: 10px 15px;
          cursor: pointer;
          img {
            margin-right: 5px;
            width: 16px;
            height: 16px;
          }
          span {
            font-family: $InterRegular;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.4;
            color: var(--grey-1);
          }
          &:first-child {
            &:hover {
              background: var(--screenz-main-hover);
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
            }
          }

          &:last-child {
            &:hover {
              background: var(--screenz-main-hover);
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
            }
          }

          // &:hover {
          //   background: var(--screenz-main-hover);
          //   border-radius: 8px;
          // }
        }
      }
    }
  }
}
