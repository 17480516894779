@import 'sass/fontFamilies.scss';

.webContentPreview {
  height: 100%;
  .drawerHeader {
    display: flex;
    justify-content: space-between;
    padding: 12px 15px;
    height: 60px;
    background: var(--grey-7);
    border-radius: 8px 0 0 0;
    border-bottom: solid 1px var(--grey-6);
    .colOne {
      display: flex;
      align-items: center;
      .previewHeading {
        font-family: $InterMedium;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.4;
        color: var(--grey-3);
      }
    }
    .colTwo {
      display: flex;
      align-items: center;

      .closeIcon {
        cursor: pointer;
      }
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    padding: 20px;
    // padding-top: 42px;
    flex-grow: 1;
    overflow: auto;
    height: 80%;
    .webSiteHeading {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      img {
        width: 24px;
        height: 24px;
        margin-right: 5px;
      }
      p {
        font-family: $InterMedium;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.32px;
        color: var(--grey-1);
      }
    }
    .inputAndSaveBtn {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      input {
        margin-right: 10px;
        padding: 10px;
        border-radius: 8px;
        border: solid 1px var(--grey-5);
        font-family: $InterRegular;
        font-size: 13px;
        line-height: 1.15;
        letter-spacing: 0.2px;
        color: var(--grey-1);
        &:focus {
          outline: none;
        }
      }
      .saveBtn {
        font-family: $InterMedium;
        font-size: 14px;
        font-weight: 500;
        color: var(--grey-4);
        padding: 8px 15px;
        &:disabled {
          background: transparent;
          opacity: 0.8;
          cursor: default;
        }
      }
    }
    .webIcon {
      width: 100%;
      max-width: 302px;
      height: 100vh;
      max-height: 170px;
      background-color: var(--grey-6);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;
      img {
        width: 40%;
      }
    }
    .url {
      font-family: $InterMedium;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.28px;
      color: var(--grey-1);
      margin-bottom: 13px;
    }
    .refresh {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 15px;
      img {
        width: 22px;
        height: 22px;
        margin-right: 5px;
      }
      font-family: $InterRegular;
      font-size: 13px;
      line-height: 1.15;
      letter-spacing: 0.2px;
      color: var(--grey-1);
    }
    .editWebPageBtn {
      width: 132px;
      height: 36px;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
