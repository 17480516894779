@import 'sass/fontFamilies.scss';

.authenticateVerifyCode {
  max-width: 390px;
  .verifyCodeHeading {
    font-family: $InterMedium;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.28px;
    color: var(--grey-1);
    margin-bottom: 8px;
  }
  .verifyCodeDescription {
    font-family: $InterRegular;
    font-size: 13px;
    line-height: 1.15;
    letter-spacing: 0.2px;
    color: var(--grey-4);
    margin-bottom: 41px;
  }

  .verificationCodeToEmail {
    font-family: $InterMedium;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
    color: #33373a;
    margin-bottom: 10px;
  }
  .verifyCodeInput {
    border: 1px solid var(--grey-5);
    margin: auto;
    width: 75%;
    height: 50px;
    border-radius: 8px;
    display: block;
    margin-bottom: 17px;
    text-align: center;
    font-family: $InterRegular;
    font-size: 14px;
    line-height: 1.4;
    text-align: center;
    color: var(--grey-4);
    &:focus {
      outline: 1px solid var(--grey-5);
    }
  }
  .sendNewCode {
    font-family: $InterMedium;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
    color: var(--screenz-main);
    cursor: pointer;
  }
}
