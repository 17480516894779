@import 'sass/fontFamilies.scss';

input:checked {
  background-color: red;
}
.playlists {
  margin: 0 -18px;
  background: var(--white);
  overflow: auto;
  flex-grow: 1;

  .playlist {
    display: flex;
    padding: 0 15px;
    border-bottom: 1px solid var(--grey-6);
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    .colOne {
      padding: 10px 0;
      display: flex;
      align-items: center;
      flex-grow: 1;
      position: relative;
      .playlistsIcon {
        margin-right: 10px;
      }
      .playlistName {
        p,
        span {
          font-family: $InterRegular;
          font-size: 13px;
          color: #33373a;
        }
        span {
          color: #7f8fa4;
        }
      }
      .checkBox {
        margin-right: 10px;
      }
    }
    .colTwo {
      display: flex;
      align-items: center;
      .clockIcon {
        margin-right: 8px;
      }
      .screenIcon {
        margin-right: 21px;
        path {
          fill: #8a8daa;
        }
      }
      .playlistLength {
        font-family: $InterRegular;
        font-size: 13px;
      }
    }
    &:hover {
      background: var(--screenz-main-hover);
    }
    &.active {
      background: var(--screenz-main-hover);
      border: 1px solid #615ae2;
    }

    &:last-child {
      margin-bottom: 1rem;
    }
  }
  @media screen and (min-width: 769px) {
    display: none;
  }
}
