@import 'sass/fontFamilies.scss';

.authenticateWithApp {
  max-width: 390px;
  .qrAuthHeading {
    font-family: $InterMedium;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.28px;
    color: var(--grey-1);
    margin-bottom: 8px;
  }
  .qrAuthDescription {
    font-family: $InterRegular;
    font-size: 13px;
    line-height: 1.15;
    letter-spacing: 0.2px;
    color: var(--grey-4);
    margin-bottom: 17px;
  }
  .qrContainer {
    border-radius: 10px;
    border: solid 1px var(--grey-6);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    margin-bottom: 12px;
    .qrCode {
      width: 112px;
      height: 112px;
    }
  }
  .verificationCode {
    font-family: $InterMedium;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
    color: #33373a;
    margin-bottom: 10px;
  }
  .codeInput {
    border: 1px solid var(--grey-5);
    margin: auto;
    width: 75%;
    height: 50px;
    border-radius: 8px;
    display: block;
    margin-bottom: 17px;
    text-align: center;
    &:focus {
      outline: 1px solid var(--grey-5);
    }
  }
  .needHelp {
    font-family: $InterMedium;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
    color: var(--screenz-main);
    cursor: pointer;
  }
}
