@import 'sass/fontFamilies.scss';

.avatarGroup {
  display: flex;
  align-items: center;
  position: relative;
  height: 42px;
  min-width: 42px;
  .avatar {
    position: absolute;
  }
  .accessToOnlyOne {
    font-family: $InterRegular;
    font-size: 13px;
    line-height: 1.15;
    letter-spacing: 0.2px;
    color: var(--grey-4);
    position: absolute;
    left: 45px;
  }
}
