@import 'sass/fontFamilies.scss';

.localLayout {
  height: 100%;
  display: none;
  grid-template-columns: 260px 1fr;
  @media screen and (min-width: 800px) {
    display: grid;
  }
  .sidebar {
    height: 100%;
    background: var(--white);
    padding: 20px;
    border-right: var(--border);
  }
  .right {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .subHeader {
      height: 60px;
      min-height: 60px;
      max-height: 60px;
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      align-items: center;
      border-bottom: var(--border);
      font-family: $InterMedium;
      font-size: 16px;
      font-weight: 500;
      color: var(--grey-1);

      .screenzBtn {
        font-size: 15px;
        font-weight: 500;
        font-family: $InterMedium;
        padding: 10px 12px;
      }
    }

    .mainContent {
      flex-grow: 1;
      overflow: auto;
      padding: 0 10px;
      padding-top: 30px;
      .container {
        display: grid;
        grid-template-columns: 45% 55%;
      }
    }
  }
}
.responsiveContainer {
  @media screen and (min-width: 800px) {
    display: none;
  }
  height: 110vh !important;
}
