@import '../../../sass//fontFamilies.scss';

  .verifyEmailWrapper {
  height: 100%;
  position: relative;

  .section {
    .verifyEmailContainer {
      width: 100%;
      min-width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (min-width: 480px) {
        width: 28%;
        min-width: 423px; 
      }
      .logo {
        margin-bottom: 18px;
      }
      .verify {
        margin-bottom: 28px;
        font-size: 24px;
        font-weight: 600;
        font-family: $InterSemiBold;
      }
      .emailIcon {
        width: 323px;
      }
      .paragraphOne, .paragraphTwo {
        text-align: center;
        font-family: $InterRegular;
        font-size: 16px;
        .email {
          color: #615ae2;
        }
      }
      .paragraphOne {
        margin-bottom: 20px;
      }
      .paragraphTwo {
        margin-bottom: 23px;
      }

      .startOverAgain {
        font-family: $InterMedium;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }


}
