@import 'sass/fontFamilies.scss';

.passwordWrapper {
  background: var(--white);
  @media screen and (max-width: 800px) {
    margin-bottom: 20px;
    border: var(--border);
    padding: 20px 10px;
  }
  .sectionHeading {
    margin-bottom: 20px;
    @media screen and (min-width: 800px) {
      display: none;
    }
  }
  .heading {
    font-family: $InterMedium;
    font-size: 14px;
    font-weight: 500;
    color: var(--grey-1);
    margin-bottom: 11px;
  }
  .description {
    color: var(--grey-3);
    font-family: $InterRegular;
    font-size: 13px;
    margin-bottom: 16px;
    line-height: 1.4;
  }

  .turnOnBtn {
    font-family: $InterMedium;
    font-size: 15px;
    font-weight: 500;
    background: var(--grey-7);
    color: var(--grey-1);
    border: 0;
    box-shadow: 0px 0px;
    &:hover {
      color: #ffff;
    }
  }
}
