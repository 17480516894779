@import "sass/fontFamilies.scss";

.controllerWrapper {
  background: var(--white);
  height: fit-content;
  .sectionHeading {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    .svg {
      margin-right: 5px;
      path {
        fill: #8a8daa;
      }
    }
    p {
      font-size: 14px;
      font-weight: 500;
      font-family: $InterMedium;
      color: #111216;
    }
  }
  .action {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @media screen and (min-width: 769px) and(max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;
    }
    button {
      cursor: pointer;
    }
    &.brightness {
      margin: 20px 0;
    }
    &.volume {
      margin: 20px 0;
    }
    &.restartPlayer {
      @media screen and (max-width: 800px) {
        margin-bottom: 25px;
      }
    }
    p {
      min-width: 55px;
      max-width: 115px;
      width: 100%;
      font-family: $InterRegular;
      font-size: 13px;
      color: #111216;
      font-family: "Inter-Regular";
      font-size: 13px;
      color: #111216;
      @media screen and (min-width: 769px) and(max-width: 1024px) {
        margin-bottom: 7px;
      }
    }
    .reboot,
    .rebooting,
    .restart,
    .restarting,
    .standbyBtnGroup {
      > img {
        margin-right: 5px;
      }
      display: flex;
      align-items: center;
      padding: 10px 12px;
      border: 1px solid #acaec5;
      background: transparent;
      font-size: 15px;
      font-weight: 500;
      font-family: $InterMedium;
      color: #8a8daa;
      border-radius: 8px;
    }
    .rebooting,
    .restarting {
      background-color: #f0f3f8;
      .loader {
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
        @-webkit-keyframes spin {
          0% {
            -webkit-transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
          }
        }
      }
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
    .standbyBtnGroup {
      padding: 0;
      border: 0;
      .on {
        border: 0;
        padding: 12px;
        background: #ffffff;
        border: 1px solid #acaec5;
        color: #8a8daa;
        border-radius: 8px 0 0 8px;
        display: flex;
        align-items: center;
        svg {
          margin-right: 5px;
        }
        &.active {
          background: #36af47;
          border: 1px solid #36af47;
          color: #fff;
          svg {
            margin-right: 5px;
            path {
              fill: #ffffff;
            }
          }
        }
      }
      .off {
        border: 0;
        padding: 12px;
        background: #ffffff;
        border-radius: 0px 8px 8px 0px;
        border: 1px solid #acaec5;
        display: flex;
        align-items: center;
        color: #8a8daa;
        svg {
          margin-right: 5px;
          transform: rotate(180deg);
        }
        &.active {
          background: #acaec5;
          border: 1px solid #acaec5;
          color: #fff;
          svg {
            margin-right: 5px;
            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }
  .divider {
    margin: 0 -20px;
    margin-bottom: 18px;
    border: 0;
    border-bottom: 1px solid #d8dae9;
  }
}
