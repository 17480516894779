@import 'sass/fontFamilies.scss';

.modal {
  height: 100%;
  position: fixed;
  top: 0;
  width: 100vw;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  @media screen and (min-width: 800px) {
    align-items: unset;
  }
  &.show {
    animation-name: modalShow;
    animation-duration: 0.5s;
    z-index: 100000;
    // overflow: hidden;
    overflow: auto;
    @keyframes modalShow {
      from {
        opacity: 0;
        display: none;
      }
      to {
        opacity: 1;
        display: flex;
      }
    }
  }
  &.hide {
    animation-name: modalHide;
    animation-duration: 0.5s;
    display: none;
    opacity: 0;
    @keyframes modalHide {
      from {
        opacity: 1;
        display: flex;
      }
      to {
        opacity: 0;
        display: none;
      }
    }
  }
  .backdrop {
    position: absolute;
    height: 100%;
    width: 100%;
    // background-color: rgba(0, 0, 0, 0.2);
  }
  .body {
    z-index: 1000;
    margin-top: 15.5vh;
    width: 100%;
    transition: 400ms;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    &.addContentToPlaylist {
      @media screen and (max-width: 799px) {
        height: 100vh;
        border-radius: 0;
        display: flex;
        flex-direction: column;
      }
    }

    &.clientModal {
      @media screen and (min-width: 800px) {
        margin-top: 1vh;
        margin-bottom: 12vh;
      }
    }
    @media screen and (min-width: 800px) {
      width: unset;
      height: fit-content;
    }
    .header {
      // background: #f0f3f8;
      background: #fff;
      border-radius: 10px 10px 0 0;
      padding: 25px 19px 15px 20px;
      display: flex;
      align-items: center;
      grid-gap: 10px;

      .container {
        flex-grow: 1;
        display: flex;
        align-items: center;
        .icon {
          width: 24px;
          margin-right: 10px;
        }
        .heading {
          font-size: 16px;
          font-weight: 500;
          font-family: $InterMedium;
          color: #111216;
        }
      }
      .searchBar {
        background: var(--white);
        width: 50%;
        border: 1px solid var(--grey-5);
        height: 42px;
        @media screen and (max-width: 799px) {
          display: none;
        }
      }
      .filter {
        height: 42px;
        border: 1px solid var(--grey-5);
        padding: 8px;
        font-family: $InterMedium;
        font-size: 15px;
        font-weight: 500;
        &.addContentToPlaylist {
          @media screen and (max-width: 799px) {
            display: none;
          }
        }
      }
      .uploadBtn {
        height: 40px;
        width: 40px;
        padding: 0;
        &.addContentToPlaylist {
          @media screen and (max-width: 799px) {
            display: none;
          }
        }
      }
      .closeIcon {
        cursor: pointer;
      }
      .responsiveAddBtn {
        @media screen and (min-width: 800px) {
          display: none;
        }
      }
    }
    .contentArea {
      background: #fff;
      padding: 12px 22px;
      &.addContentToPlaylist {
        @media screen and (max-width: 799px) {
          overflow: hidden;
          flex-grow: 1;
        }
      }
    }
    .footer {
      display: flex;
      justify-content: flex-end;
      background: #f0f3f8;
      padding: 11px 0;
      .btns {
        font-size: 15px;
        font-weight: 500;
        font-family: $InterMedium;
        margin-right: 13px;
      }
      @media screen and (min-width: 800px) {
        border-radius: 0 0 10px 10px;
        padding: 11px 0;
      }
    }
  }
}
