.previewWrapper {
  width: 80px;
  min-width: 80px;
  height: 45px;
  // max-height: 45px;
  margin-right: 5px;
  background: var(--grey-7);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 2px;
  overflow: hidden;

  img {
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
  .videoPlayerIcon {
    position: absolute;
    width: 24px !important;
    height: 24px !important;
    // display: none;
  }
}
