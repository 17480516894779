@import '../../../sass/fontFamilies.scss';

.loginWrapper {
  height: 100%;
  position: relative;
  .header {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 1;
    .signInBtn {
      font-family: $InterMedium;
      font-size: 15px;
      font-weight: 500;
      color: #615ae2;
      cursor: pointer;
    }
    .dontHaveAccount {
      margin-left: 5px;
      font-size: 10px;
      color: #111216;
      font-size: 14px;
      font-family: $InterRegular;
      cursor: pointer;
    }
  }
  .section {
    .joinInForm,
    .loginContainer {
      width: 100%;
      min-width: 200px;
      transition: 400ms;
      @media screen and (min-width: 480px) {
        max-width: 423px;
      }
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .logo {
        margin-bottom: 10px;
      }
      .joinInMessage {
        margin-bottom: 5px;
        font-size: 24px;
        font-weight: 600;
        font-family: $InterSemiBold;
      }
      .enterDetails {
        font-family: $InterRegular;
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 42px;
      }
      .enterEmail,
      .enterPassword {
        color: #7c7e90;
        font-family: $InterRegular;
        font-size: 13px;
        margin-bottom: 10px;
      }
      .inputFirstName {
        margin-bottom: 5px;
        font-family: $InterRegular;
        font-size: 14px;
        color: #8a8daa;
        background-color: #ffffff !important;
        &.password {
          input {
            background: #e9f0fd;
          }
        }
        &.first {
          margin-bottom: 20px;
        }
      }
      .inputEmail {
        font-family: $InterRegular;
        font-size: 14px;
        color: #8a8daa;
        margin-bottom: 20px;
        background-color: #f4f5f7;
        &.companyName {
          background-color: #fff;
        }
      }
      .input {
        margin-bottom: 20px;
        font-family: $InterRegular;
        font-size: 14px;
        color: #8a8daa;
        background-color: #d8dae9;
      }
      .createPassword {
        font-family: $InterRegular;
        font-size: 14px;
        color: #8a8daa;
      }
      .passwordHelperText {
        font-size: 11px;
        font-weight: 500;
        font-family: $InterMedium;
        color: #8a8daa;
        margin-bottom: 28px;
        // margin-top: 10px;
      }
      .termsAndConditions {
        font-size: 14px;
        font-weight: 500;
        font-family: $InterMedium;
        color: #5b5d70;
        max-width: 370px;
        margin-bottom: 20px;
        a {
          text-decoration: none;
          margin-left: 2px;
          color: #615ae2;
        }
      }
      .joinBtn {
        align-self: flex-end;
        font-family: $InterMedium;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 30px;
      }
      position: relative;
      .tempCreds {
        position: absolute;
        bottom: -105px;
        h4 {
          margin-bottom: 10px;
        }
        .select {
          user-select: all;
          display: inline-block;
        }
      }
      .errorMessage {
        font-family: $InterRegular;
        font-size: 24px;
        align-items: center;
        text-align: center;
        @media screen and (min-width: 800px) {
          width: 170%;
          margin-left: -11vw;
          margin-right: -11vw;
        }

        line-height: 2rem;
      }
    }
  }
}
