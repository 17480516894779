@import 'sass/fontFamilies.scss';

$openSpeed: 500ms;

.search {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 30px;
  border-radius: 8px;
  border: 0;
  transition: $openSpeed;
  &.focused {
    border-image-source: linear-gradient(97deg, #9f77ed -23%, #635ce6 45%);
    border-image-slice: 1;
    background-image: linear-gradient(to bottom, #fff, #fff),
      linear-gradient(97deg, #9f77ed -23%, #635ce6 45%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 0px 0px 0px 3px #cdcdf2;
  }
  &.open {
    width: 100%;
    border: 1px solid #d8dae9;
    @media screen and (min-width: 800px) {
      border: 1px solid #615ae2;
    }
  }
  .searchIcon {
    cursor: pointer;
    margin-left: 10px;
    transition: $openSpeed;
    width: 20px;
    height: 20px;
    &.open {
      height: 14px;
      width: 14px;
    }
  }
  .input {
    border: 0;
    background: transparent;
    font-size: 15px;
    font-family: $InterRegular;
  }
  .cancelIcon {
    cursor: pointer;
    height: 16px;
    width: 16px;
    margin-right: 10px;
  }
}
