@import 'sass/fontFamilies.scss';

.authTypeWrapper {
  width: 95vw;
  max-width: 543px;

  .sectionHeading {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 25px;
    margin-bottom: 35px;
    .icon {
      min-width: 116px;
      height: 110px;
    }
    .sectionDescription {
      max-width: 340px;
      .heading {
        font-family: $InterMedium;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.28px;
        color: var(--grey-1);
      }
      .description {
        font-family: $InterRegular;
        font-size: 13px;
        line-height: 1.15;
        letter-spacing: 0.2px;
        color: var(--grey-4);
      }
    }
  }
  $activeBorder: 1.3px solid var(--screenz-main);
  $nonActiveBorder: 1.3px solid var(--grey-6);
  .authenticationTypes {
    grid-gap: 10px;
    margin-bottom: 30px;
    display: grid;
    @media screen and (min-width: 800px) {
      grid-template-columns: 1fr 1fr;
    }
    .authenticationCard {
      flex-grow: 1;
      border: $nonActiveBorder;
      border-radius: 10px;
      padding: 15px;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      &.active {
        border: $activeBorder;
      }
      .cardHeading {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        .authenticateType {
          font-family: $InterMedium;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.28px;
          color: var(--grey-1);
        }
        .recommendedTag {
          position: absolute;
          background: var(--screenz-main);
          right: -16px;
          padding: 9px;
          border-radius: 8px 0 0 8px;
          font-family: $InterMedium;
          font-size: 11px;
          font-weight: 500;
          color: var(--white);
        }
      }
      .cardBody {
        font-family: $InterRegular;
        font-size: 13px;
        line-height: 1.15;
        letter-spacing: 0.2px;
        color: var(--grey-4);
        margin-bottom: 15px;
        flex-grow: 1;
      }
      .cardFooter {
        height: 50px;
        margin: 15px -15px -15px -15px;
        border-radius: 0 0 10px 10px;
        border-top: $nonActiveBorder;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--grey-7);
        &.active {
          border-top: $activeBorder;
          background: #e7e6fb;
        }
        .checkbox {
          cursor: pointer;
          width: 32px;
          height: 32px;
          border-radius: 100%;
          border: 3px solid var(--grey-5);
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          .checkIcon {
            width: 15px;
            path {
              fill: #fff;
            }
          }
          &.active {
            border: 3px solid #fff;
            background: var(--screenz-main);
          }
        }
      }
    }
  }
}
