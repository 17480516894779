.slider-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}
.slider-container input {
  width: 100%;
}
.slider-container .bar {
  width: 100%;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 4px;
  border-radius: 5px;
  background-color: var(--grey-6);
  overflow: hidden;
}
.slider-container .bar .fill {
  display: block;
  width: 0;
  height: 100%;
  background-color: var(--screenz-main);
}
.slider-container .slider {
  position: relative;
  z-index: 2;
  -webkit-appearance: none;
  height: 10px;
  border-radius: 5px;
  outline: none;
  background-color: transparent;
}
.slider-container .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  border: solid 1px var(--grey-6);
  background: #fff;
  transition: 0.3s ease-in-out;
}
.slider-container .inProgress::-webkit-slider-thumb {
  box-shadow: 0 0 2px 5px #d8d6f8;
  background-color: var(--screenz-main);
}
.slider-container .slider::-webkit-slider-thumb:hover {
  box-shadow: 0 0 2px 5px #d8d6f8;
}
.slider-container .inProgress:active::-webkit-slider-thumb {
  box-shadow: 0 0 2px 5px #d8d6f8;
}
