@import "sass/fontFamilies.scss";
.teamMembersContainer {
    width: 100%;
    @media screen and (max-width: 769px) {
        display: none;
    }
    .tabList {
        display: flex;
        width: 100%;
        border-bottom: 1px solid var(--grey-6);
        @media screen and (max-width: 769px) {
            display: none;
        }
        .notActiveTabs,
        .activeTabs {
            padding: 10px;
            font-family: $InterRegular;
            font-size: 14px;
            font-weight: 500;
            color: #615ae2;
            border-radius: 10px;
            line-height: 19.6px;
            transition: 400ms;
            background-color: rgba(97, 90, 226, 0.2);
            border: 0;
            margin: 5px;
            margin-left: 10px;
        }
        .notActiveTabs {
            color: #5b5d70;
            background-color: transparent;
            box-shadow: none;
        }
    }
    .tabDetails {
        margin: 20px;
        @media screen and (max-width: 769px) {
            display: none;
        }
        @media screen and (max-width: 820px) {
            margin: 0;
        }
    }
}
.teamMembersMobContainer {
    width: 100%;
    background-color: #ffffff;
    @media screen and (min-width: 769px) {
        display: none;
    }
    .mobTabList {
        display: flex;
        border-bottom: 1px solid var(--grey-6);
        .activeMobTabs {
            padding: 10px;
            font-family: $InterRegular;
            font-size: 14px;
            font-weight: 500;
            color: #615ae2;
            border-radius: 10px;
            line-height: 19.6px;
            transition: 400ms;
            background-color: rgba(97, 90, 226, 0.2);
            border: 0;
            margin: 6px;
        }
        .notActiveMobTabs {
            padding: 10px;
            font-family: $InterRegular;
            font-size: 14px;
            font-weight: 500;
            color: #5b5d70;
            border-radius: 10px;
            line-height: 19.6px;
            transition: 400ms;
            background-color: transparent;
            border: 0;
            box-shadow: none;
            margin: 6px;
        }
    }
}
