@import 'sass/fontFamilies.scss';

.screenDetailsSubHeader {
  padding: 10px;
  border-bottom: 1px solid var(--grey-6);
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and(max-width: 800px) {
    background: var(--white);
  }
  .colOne {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    .changeViewBtn {
      @media screen and (min-width: 800px) {
        display: none;
      }
    }
  }
  .colTwo {
    .onlineStatusPill {
      @media screen and (min-width: 800px) {
        display: none;
      }
    }
    .pingTime {
      @media screen and (max-width: 800px) {
        display: none;
      }
    }
  }
}
