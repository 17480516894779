@import 'sass/fontFamilies.scss';

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  @media screen and (max-width: 800px) {
    display: none;
  }
  .btns {
    margin-left: 10px;
    font-family: $InterMedium;
    font-size: 15px;
    font-weight: 500;
  }
}
