@import 'sass/fontFamilies.scss';
.playlistDropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: solid 1px transparent;
  // padding: 0px 14px;
  gap: 5px;
  background: transparent;
  border-radius: 8px;
  border: none;
  .playlistsIcon {
    width: 21px;
    margin-right: 5px;
  }
  .playlistName {
    font-family: $InterMedium;
    font-size: 15px;
    font-weight: 500;
    color: var(--grey-3);
  }
  .caretFilledDropdownIcon {
    width: 10px;
    margin-left: 13px;
    path {
      fill: var(--grey-3);
    }
  }
  // &.active {
  //   border: solid 1px var(--grey-6);
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: center;
  //   align-items: center;
  //   padding: 0px 14px;
  //   gap: 5px;
  //   background: #d8dae9;
  //   border-radius: 8px;
  // }
}
.viewPopover {
  min-width: 160px;
  padding: 6px;
  box-shadow: 0 2px 6px 0 rgba(42, 44, 58, 0.25);
  border: solid 1px var(--grey-6);
  background-color: var(--white);
  left: 15px;
  border-radius: 8px;
  .viewOptions {
    list-style-type: none;
    .option {
      padding: 10px;
      color: var(--grey-1);
      font-size: 14px;
      font-family: $InterRegular;
      display: flex;
      align-items: center;
      cursor: pointer;
      .icon {
        margin-right: 10px;
      }
    }
  }
}
