@import '../../../sass//fontFamilies.scss';

.resetPasswordWrapper {
  height: 100%;
  position: relative;

  .section {
    .resetPasswordContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      min-width: 200px;
      transition: 400ms;
      @media screen and (min-width: 480px) {
        max-width: 423px;
      }
      .logo {
        margin-bottom: 10px;
      }
      .resetPassword {
        margin-bottom: 5px;
        font-size: 24px;
        font-weight: 600;
        font-family: $InterSemiBold;
      }
      .enterDetails {
        font-family: $InterRegular;
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 42px;
        max-width: 336px;
      }
      .enterEmail {
        margin-bottom: 20px;
        input {
          background: #e9f0fd;
        }
      }
      .enterEmail,
      .enterPassword {
        color: #5b5d70;
        font-family: $InterRegular;
        font-size: 13px;
      }
      .forgotPassword {
        color: #5e5be1;
        font-family: $InterMedium;
        font-size: 11px;
        cursor: pointer;
        margin-bottom: 2px;
      }
      .input {
        margin-bottom: 20px;
        font-family: $InterRegular;
        font-size: 14px;
      }
      .sendInstructions {
        align-self: flex-end;
        font-family: $InterMedium;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
}
