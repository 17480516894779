@import 'sass/fontFamilies.scss';

.addWebContentSidebar {
  border-radius: 10px 0 0 0;
  background: #fff;
  @media screen and (min-width: 800px) {
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
    height: 100%;
    margin-bottom: auto;
  }
  .sidebarHeader {
    height: 50px;
    padding: 15px;
    border: solid 1px var(--grey-6);
    background-color: var(--grey-7);
    font-family: $InterRegular;
    font-size: 14px;
    line-height: 1.4;
    color: var(--grey-1);
    @media screen and (min-width: 800px) {
      border-radius: 10px 0 0 0;
    }
  }
  .sidebarMainSection {
    padding: 15px;
    hr {
      // background: var(--grey-6);
      background: transparent;
      border: 0;
      border-bottom: 1px solid var(--grey-6);
      margin: 20px -15px;
    }
    .primaryDetailsContainer {
      // padding-bottom: 20px;
      .urlLabel {
        font-family: $InterRegular;
        font-size: 14px;
        line-height: 1.4;
        color: var(--grey-1);
        margin-bottom: 10px;
      }
      .urlInput {
        font-family: $InterRegular;
        font-size: 13px;
        line-height: 1.15;
        letter-spacing: 0.2px;
        color: var(--grey-4);
        margin-bottom: 5px;
        padding: 8px 10px;
        border: solid 1px var(--grey-5);
        background-color: var(--white);
        border-radius: 6px;
        &:focus {
          outline: none;
        }
      }
      .openWebsiteBtn {
        font-family: $InterMedium;
        font-size: 11px;
        color: var(--white);
        padding: 7px 10px;
        margin-bottom: 13px;
      }
    }
    .sizeAndScreenType {
      .sectionHeading {
        font-family: $InterRegular;
        font-size: 14px;
        line-height: 1.4;
        color: var(--grey-1);
        margin-bottom: 12px;
      }
      .dropdownContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 7px;
        width: 70%;
        border: 1px solid red;
        border: solid 1px var(--grey-5);
        background-color: #fcfcfd;
        border-radius: 8px;
        margin-bottom: 16px;
        .choosedOption {
          font-family: $InterRegular;
          font-size: 14px;
          line-height: 1.4;
          color: var(--grey-1);
          white-space: nowrap;
        }
        .dropdownIcon {
          width: 10px;
          height: 12px;
        }
      }
      .resolutionContainer {
        display: flex;
        align-items: center;
        grid-gap: 10px;
        .widthContainer,
        .heightContainer {
          display: flex;
          align-items: center;
          p {
            font-family: $InterRegular;
            font-size: 13px;
            line-height: 1.15;
            letter-spacing: 0.2px;
            color: var(--grey-3);
            margin-right: 8px;
          }
          .resolutionInput {
            width: 58px;
            padding: 8px;
            border-radius: 6px;
            border: solid 1px var(--grey-5);
            text-align: center;
            &:focus {
              outline: none;
            }
          }
        }
        .deviceTypes {
          display: grid;
          grid-template-columns: 1fr 1fr;
          width: 68px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          border-radius: 6px;
          border: solid 1px var(--grey-6);
          background-color: var(--grey-7);
          position: relative;
          cursor: pointer;
          .screen {
            width: 18px;
            height: 18px;
            left: 10%;
            position: absolute;
            path {
              fill: var(--grey-5);
            }
            &.active {
              path {
                fill: #fff;
              }
            }
          }
          .portrait {
            right: 10%;
            position: absolute;
            path {
              fill: var(--grey-5);
            }
            &.active {
              path {
                fill: #fff;
              }
            }
          }
          .activeIndicator {
            width: 50%;
            height: 30px;
            cursor: pointer;
            background-color: var(--screenz-main);
            position: absolute;
            &.landscape {
              left: 0;
              border-radius: 6px 0 0 6px;
            }
            &.portrait {
              right: 0;
              border-radius: 0 6px 6px 0;
            }
          }
        }
      }
    }
    // .refreshContainer {
    // .refreshHeading {
    //   font-family: $InterRegular;
    //   font-size: 14px;
    //   line-height: 1.4;
    //   color: var(--grey-1);
    // }
    // }

    .refreshContainer {
      .refreshHeading {
        font-family: $InterRegular;
        font-size: 14px;
        line-height: 1.4;
        color: var(--grey-1);
        margin-bottom: 10px;
        white-space: nowrap;
      }
      .refreshPopover {
        bottom: 0;
        border-radius: 8px;
        width: 70%;
        .options {
          .option {
            padding: 5px;
            font-family: $InterRegular;
            font-size: 14px;
            line-height: 1.4;
            color: var(--grey-1);
            white-space: nowrap;
            cursor: pointer;
          }
        }
      }
      .dropdownContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 7px;
        width: 70%;
        border: solid 1px var(--grey-5);
        background-color: #fcfcfd;
        border-radius: 8px;
        margin-bottom: 16px;
        cursor: pointer;
        .choosedOption {
          font-family: $InterRegular;
          font-size: 14px;
          line-height: 1.4;
          color: var(--grey-1);
          white-space: nowrap;
        }
        .dropdownIcon {
          width: 10px;
          height: 12px;
        }
      }
    }
  }
}
