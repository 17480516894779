@import '../../../sass/fontFamilies.scss';

.loginWrapper {
  height: 100%;
  position: relative;
  .header {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 1;
    .getStartBtn {
      font-family: $InterMedium;
      font-size: 15px;
      font-weight: 500;
      color: #8a8daa;
    }
    .dontHaveAccount {
      margin-left: 5px;
      font-size: 10px;
      color: #111216;
      font-size: 14px;
      font-family: $InterRegular;
      cursor: pointer;
    }
  }
  .section {
    .loginContainer {
      width: 100%;
      min-width: 200px;
      transition: 400ms;
      @media screen and (min-width: 480px) {
        max-width: 423px;
      }
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .logo {
        margin-bottom: 10px;
      }
      .signInMessage {
        margin-bottom: 5px;
        font-size: 24px;
        font-weight: 600;
        font-family: $InterSemiBold;
      }
      .enterDetails {
        font-family: $InterRegular;
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 42px;
      }

      .emailField {
        color: #5b5d70;
        font-family: $InterRegular;
        font-size: 13px;
        margin-bottom: 20px;
        input {
          background: #e9f0fd;
        }
      }
      .passwordAndForgotPasswordLink {
        position: relative;
        width: 100%;
        .forgotPassword {
          position: absolute;
          right: 0;
          z-index: 1;
          color: var(--screenz-main);
          font-family: $InterMedium;
          font-size: 11px;
          cursor: pointer;
          margin-bottom: 2px;
          text-decoration: none;
        }
      }
      .input {
        margin-bottom: 20px;
        font-family: $InterRegular;
        font-size: 14px;
        color: #8a8daa;
      }
      .loginBtn {
        align-self: flex-end;
        font-family: $InterMedium;
        font-size: 15px;
        font-weight: 500;
      }
      position: relative;
    }
  }
}
