@import '../../../sass//fontFamilies.scss';

  .resetInstructionsWrapper {
  height: 100%;
  position: relative;

  .section {
    .verifyEmailContainer {
      width: 100%;
      max-width: 600px;
      .logo {
        margin-bottom: 35px;
      }
      .heading {
        margin-bottom: 28px;
        font-size: 32px;
        font-weight: bold;
        font-family: $HelveticaBold;
        max-width: 500px;
        .screenz {
          color: #615ae2;
        }
      }
      .helloRuna {
        font-size: 18px;
        font-family: $HelveticaRegular;
        color: #707289;
        margin-bottom: 8px;
        >a {
          color: #615ae2;
          text-decoration: underline
        }
      }
      .paragraphOne {
        font-family: $HelveticaRegular;
        font-size: 18px;
        color: #5b5d70;
        margin-bottom: 30px;
      }

      .setNewPasswordBtn {
        font-family: $InterMedium;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 20px;
      }
      .paragraphTwo {
        font-size: 15px;
        color: #707289;
        font-family: $HelveticaRegular;
      }
      .paragraphThree {
        font-size: 15px;
        color: #707289;
        font-family: $HelveticaRegular;
        margin-bottom: 35px;
        .teamLink {
          display: block;
          color: #615ae2;
        }
      }
      .horizontal {
        border-top: #d8dae9;
        margin-bottom: 12px;
      }
      .copyright {
        color: #8a8daa;
        font-size: 13px;
        font-family: $HelveticaRegular;
      }
    }
  }


}
