.playlistWrapper {
  // background: #f4f5f7;
  // display: flex;
  // flex-direction: column;
  // flex-grow: 1;
  // box-sizing: border-box;
  // overflow: hidden;
  // position: relative;
  // min-height: 100%;
  height: 100%;

  .loaderWrapper {
    margin-top: 30vh;
  }
  .notFound {
    text-align: center;
    padding: 30px;
    font-weight: 500;
    font-family: 'Inter-Medium';
  }
}
